import React, { useRef } from "react";

import { Container } from "@material-ui/core";
import Slider from "react-slick";

import leftWing from "../../assets/image/bayTreeLeft.png";
import rightWing from "../../assets/image/bayTreeRight.png";

import reviewsText from "./reviewsText";

import StoreButton from "../Button/StoreButton";

import Fade from "react-reveal/Fade";

import "./Reviews.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Reviews() {
  const sliderRef = useRef();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div
      className="introContainer white reviewsIntroContainer"
      style={{ flexDirection: "column", alignItems: "center" }}
    >
      {/* <div className="featureDivider"></div> */}
      <Container style={{ display: "flex", flexDirection: "row" }}>
        <Fade bottom distance="60px">
          <div className="reviewsContainer">
            <div className="wingContainer">
              <img src={leftWing} className="wing" alt="leftWing" />
            </div>
            <div className="reviewSliderContainer">
              <Slider
                ref={sliderRef}
                dotsClass="slick-dots reviewSliderDots"
                {...sliderSettings}
              >
                {reviewsText.map((item, index) => (
                  <div key={index}>
                    <span className="reviewTitle">"{item.title}"</span>
                    <span className="reviewRate">★★★★★</span>
                    <div className="reviewTextContainer">
                      <span className="reviewContent">{item.content}</span>
                      <span className="reviewAuthor">
                        {item.source} 리뷰, {item.author}님
                      </span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="wingContainer">
              <img src={rightWing} className="wing" alt="rightWing" />
            </div>
          </div>
        </Fade>
      </Container>
      <Fade bottom distance="60px" delay={200}>
        <div className="withTickleContainer">
          <span className="withTickleText">
            이제, <b>티클과 함께</b> 일상을 바꿔보세요.
          </span>
          {/* <div className="withTickleIcons">
            <StoreButton type="apple" />
            <StoreButton type="play" />
          </div> */}
        </div>
      </Fade>
    </div>
  );
}
