import React from 'react';
import styled from 'styled-components';

import largeConvenience from "../../assets/image/largeShinhanSamEvent.png";
import baseConvenience from "../../assets/image/baseShinhanSamEvent.png";

import {
  isMobile
} from "react-device-detect";
import ReactPixel from 'react-facebook-pixel';


const Banner = () => {

  const onClick = () => {
    window.location.href = '/promotion/invest_money_support'
    window.airbridge.events.send("invest_money_support");
    ReactPixel.track('invest_money_support');

  }
  return (
    <Container>
      {isMobile ? (
        <BannerImage src={baseConvenience} onClick={onClick}/>
      ) : (
        <BannerImage src={largeConvenience} onClick={onClick} />
      )}
    </Container>
  )
}

const Container = styled.button`
  display: block;
  width: 100%;
  background-color: #fff;
  text-align: center;
  margin-bottom: 0px;
  padding: 32px 0;
  border: 0;
  cursor: pointer;
`;

const BannerImage = styled.img`
  width: 95%;
  height: auto;
  margin: auto;
`;

export default Banner;