import React from "react";
import "./Recruit.css";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import ArrowRight from "@material-ui/icons/ArrowForwardIos";

const RecruitButton = withStyles({
  root: {
    background: "#fff",
    height: 59,
    width: "100%",
    paddingLeft: 30,
    borderRadius: 0,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.22)",
    "&:hover": {
      transitionDuration: "0.5s",
    },
    transitionDuration: "0.5s",
    textAlign: "left",
  },
  label: {
    width: "100%",
    textTransform: "capitalize",
    fontSize: 24,
    fontFamily: ["Spoqa Han Sans", "sans-serif"],
    display: "flex",
    fontWeight: 300,
    color: "#3e3e3e",
    justifyContent: "space-between",
    "@media  (min-width: 320px) and (max-width: 767px)": {
      fontSize: 18,
    },
  },
})(Button);

const Developer = () => (
  <RecruitButton href="https://www.notion.so/primavera/e3bdfec5c741459f96544d7d8166fdfa">
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <span style={{ color: 'red', fontSize: 8, marginRight: 8 }}>NEW</span> */}
      Software Engineer
    </div>
    <ArrowRight fontSize="small" className="recruitArrow" />
  </RecruitButton>
);

const Designer = () => (
  <RecruitButton href="https://www.notion.so/primavera/UX-UI-Designer-837cd42a55e94d0b85fa506ecd9452d8">
    UX/UI Designer
    <ArrowRight fontSize="small" className="recruitArrow" />
  </RecruitButton>
);

const PM = () => (
  <RecruitButton href="https://www.notion.so/primavera/Product-42676237699d48a786087e44df545a7b">
    Product Manager
    <ArrowRight fontSize="small" className="recruitArrow" />
  </RecruitButton>
);

export default class Questions extends React.PureComponent {
  render() {
    return (
      <Container className="faqContainer">
        <h1 className="menuTitle">채용</h1>
        {/* <p>현재 진행중인 채용이 없습니다.</p> */}
        <Developer />
        {/* <Designer /> */}
        {/* <PM /> */}
      </Container>
    );
  }
}
