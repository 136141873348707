import React, { Component } from 'react';
import {
  isIOS
} from "react-device-detect";

const storeLink = ()=>{
  if(isIOS){
    // ReactGA.event({category: 'appDownload',action:'click', label:'apple'});
    return 'https://apps.apple.com/kr/app/%ED%8B%B0%ED%81%B4-%EC%9E%94%EB%8F%88-%EC%A0%80%EC%B6%95-%ED%88%AC%EC%9E%90/id1457530242'
  }
  else {
    // ReactGA.event({category: 'appDownload',action:'click', label:'play'})
    return 'https://play.google.com/store/apps/details?id=com.tickleinvest.tickle';

  }
}
class Download extends Component {
  componentWillMount(){
    window.location = storeLink()
  }
  render(){
    return (
      <a href={storeLink()}>다운로드 페이지로 이동합니다...</a>
    );
  }
}

export default Download;
