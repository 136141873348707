import React from 'react';

import styled from 'styled-components';
import ReactPixel from 'react-facebook-pixel';

import shinhanSamEvent from "../../assets/image/promotion/investMoneySupport/shinhanSamEvent.png";
import tickletterFollow from "../../assets/image/promotion/investMoneySupport/tickletterFollow.png";
import notice from "../../assets/image/promotion/investMoneySupport/notice.png";

const InvestMoneySupport = () => {
  const onClick = () => {
    window.location.href = 'http://link.tickleinvest.com/web30000'
    ReactPixel.track('InitiateCheckout');
  }
  const onClickLetter = () => {
    ReactPixel.track('Schedule');
    window.location.href = 'http://link.tickleinvest.com/30000letter'

  }
  return (
    <Container>
      <FullImage src={shinhanSamEvent}/>
      <FullImage src={tickletterFollow} onClick={onClickLetter} style={{marginBottom: 16, marginTop: 32}}/>
      <FullImage src={notice} />
      <Button onClick={onClick}>
        <ButtonText>
          티클 앱 다운받기
        </ButtonText>
      </Button>
    </Container>
  )
}

const Container = styled.div`
  background-color: #ffffff;
  padding-bottom: 50px;
  text-align: center;
`;

const FullImage = styled.img`
  @media  (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ff9016;
  z-index: 1;
  border: 0;
  padding-top: 6px;
`;

const ButtonText = styled.span`
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  /* font-family: 'Recipekorea'; */
`;
export default InvestMoneySupport;