import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import terms_file from "../assets/html/terms_file";



function Home() {

  useEffect(() => {
    window.location = "https://tickle-public.s3.ap-northeast-2.amazonaws.com/terms/terms_of_tickle_service.pdf"
  }, [])

  return (
    <div className="root">
      {/* <Container>
        <div style={{ marginTop: 120, marginBottom: 120 }} dangerouslySetInnerHTML={{ __html: terms_file }}></div>
      </Container> */}
    </div>
  );
}

export default Home;
