import React from "react";
import {
  Home,
  Terms,
  Privacy,
  Help,
  Recruit,
  Download,
  Promotion,
  Invite,
  BCAgree,
} from "./pages";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

// Google Analytics
ReactGA.initialize("UA-142119222-1");
ReactGA.pageview(window.location.pathname + window.location.search);

// Facebook Pixel
ReactPixel.init("538808383352927");
ReactPixel.pageView();

function App() {
  return (
    <Router>
      <div className="root">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/help" component={Help} />
          {/* <Route path="/recruit" component={Recruit}/> */}
          <Route path="/download" component={Download} />
          <Route path="/promotion/:promotion_title" component={Promotion} />
          {/* <Route path="/invite" component={Home} /> */}
          <Route path="/inviteTest" component={Invite} />
          <Route path="/bcagree" component={BCAgree} />
          <Route component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
