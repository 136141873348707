import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import mainLogo from "../assets/image/main-logo.png";
import detailLogo from "../assets/image/detail-logo.png";
import Container from "@material-ui/core/Container";

// import Badge from '@material-ui/core/Badge';

import { Link } from "react-router-dom";

import "./AppBar.css";

export default class ButtonAppBar extends React.PureComponent {
  render() {
    return (
      <AppBar
        position="absolute"
        className={"AppBar " + (this.props.orange ? "orange" : null)}
      >
        <Container>
          <Toolbar>
            <Link to="/" style={{ display: "flex", alignItems: "center" }}>
              <img
                src={!this.props.orange ? mainLogo : detailLogo}
                className="appbarIcon"
                alt="icon"
              />
            </Link>
            {/* <div   style={{marginRight: 0,marginLeft: 'auto'}}> */}
            {/* <Link to="/recruit" className={'navLink '+(this.props.orange?'orange':null)}>채용</Link> */}
            {/* </div> */}
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}
