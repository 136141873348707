import React from 'react';

import Fade from 'react-reveal/Fade';

import Container from '@material-ui/core/Container';

import cardSite from "../../assets/image/illust/safeSite.png";
import ticklehistory from "../../assets/image/pieDetail.png";

import './Spare.css';

export default function Divide() {

  return (
    <div className="introContainer">
      <Container style={{ display: "flex"}} className="columnInMobile">
          <div className="spareHalf">
            <Fade bottom distance="80px" delay={200} className="introTexts">
              <img src={cardSite} className="titleIcon" />
              <p className="introTitle">분산해서 <b>안전</b>하게</p>
              <span className="introContent">
                여러 기업에 나눠서<br></br>
                안전하게 분산투자하세요!
              </span>
            </Fade>
          </div>
          <div className="spareHalf">
            <Fade bottom distance="80px">
              <img src={ticklehistory} className="intro-mockup" alt="ticklehistory"/>
            </Fade>

          </div>
      </Container>
    </div>
  );
}
