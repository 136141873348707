module.exports = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"맑은 고딕";
	panose-1:2 11 5 3 2 0 0 2 0 4;}
@font-face
	{font-family:AppleSDGothicNeoEB00;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:AppleSDGothicNeoM00;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:AppleSDGothicNeoH00;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"@AppleSDGothicNeoM00";}
@font-face
	{font-family:"@맑은 고딕";}
@font-face
	{font-family:"@AppleSDGothicNeoEB00";}
@font-face
	{font-family:"@AppleSDGothicNeoH00";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"맑은 고딕",sans-serif;}
h1
	{margin-top:3.45pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:5.0pt;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:14.0pt;
	font-family:AppleSDGothicNeoH00;
	font-weight:normal;}
h2
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:5.0pt;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:AppleSDGothicNeoEB00;
	font-weight:normal;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin-top:7.95pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:43.0pt;
	margin-bottom:.0001pt;
	text-indent:-18.0pt;
	text-autospace:none;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:7.95pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:43.0pt;
	margin-bottom:.0001pt;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-18.0pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"맑은 고딕",sans-serif;}
p.TableParagraph, li.TableParagraph, div.TableParagraph
	{mso-style-name:"Table Paragraph";
	margin:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:11.0pt;
	font-family:"맑은 고딕",sans-serif;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{text-autospace:none;}
 /* Page Definitions */
 @page WordSection1
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection3
	{page:WordSection3;}
@page WordSection4
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection4
	{page:WordSection4;}
@page WordSection5
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection5
	{page:WordSection5;}
@page WordSection6
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection6
	{page:WordSection6;}
@page WordSection7
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection7
	{page:WordSection7;}
@page WordSection8
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection8
	{page:WordSection8;}
@page WordSection9
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection9
	{page:WordSection9;}
@page WordSection10
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection10
	{page:WordSection10;}
@page WordSection11
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection11
	{page:WordSection11;}
@page WordSection12
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection12
	{page:WordSection12;}
@page WordSection13
	{size:595.5pt 842.0pt;
	margin:79.0pt 66.0pt 14.0pt 67.0pt;}
div.WordSection13
	{page:WordSection13;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=KO>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-top:3.25pt;margin-right:auto;
margin-bottom:0cm;margin-left:auto;margin-bottom:.0001pt;text-align:center'><span
style='font-size:16.0pt;font-family:AppleSDGothicNeoM00'>티클<span
style='letter-spacing:2.65pt'> </span>이용약관</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-family:AppleSDGothicNeoM00'>&nbsp;</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:11.0pt;font-family:AppleSDGothicNeoM00'>&nbsp;</span></p>

<h2 style='margin-top:3.7pt'>제 1조 (목적)</h2>

<p class=MsoBodyText style='margin-top:7.65pt;margin-right:5.75pt;margin-bottom:
0cm;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0cm;line-height:102%'>본 약관은 회원이 주식회사 티클 (이하 “회사”라 함)에서
제공하는 &quot;티클 서비스”(이하 “서비스”라 함)의 이용과 관련하여, 회사와 회원간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스
이용  조건<span style='letter-spacing:1.4pt'> </span>및<span style='letter-spacing:
1.45pt'> </span>절차<span style='letter-spacing:1.45pt'> </span>등<span
style='letter-spacing:1.45pt'> </span>기본적인<span style='letter-spacing:1.45pt'> </span>사항을<span
style='letter-spacing:1.45pt'> </span>규정함을<span style='letter-spacing:1.45pt'> </span>목적으로<span
style='letter-spacing:1.5pt'> </span>합니다.</p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 2조 (용어의 정리)</h2>

<p class=MsoListParagraph align=left style='margin-top:7.65pt;text-align:left'><span
style='font-size:10.0pt'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>약관에서<span style='letter-spacing:
1.4pt'> </span>사용하는<span style='letter-spacing:1.45pt'> </span>용어의<span
style='letter-spacing:1.5pt'> </span>정의는<span style='letter-spacing:1.45pt'> </span>다음과<span
style='letter-spacing:1.45pt'> </span>같습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>&quot;티클앱&quot;이란 스마트폰, 태블릿 등 휴대용 단말기(이하
&quot;모바일 디바이스&quot;라 함)에 설 치되어<span style='letter-spacing:1.4pt'> </span>회사가<span
style='letter-spacing:1.5pt'> </span>제공하는<span style='letter-spacing:1.4pt'> </span>서비스를<span
style='letter-spacing:1.45pt'> </span>구현하는<span style='letter-spacing:1.45pt'> </span>애플리케이션을<span
style='letter-spacing:1.45pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>&quot;회원&quot;이란 본 약관에 따라 가입신청에 대한 회사의
승낙을 받아 회사가 제공하는 서 비스를 이용하는 자를<span style='letter-spacing:.8pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:7.95pt;margin-right:
5.75pt;margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>&quot;회원 ID&quot;라 함은 회원의 식별과 회원의 서비스
이용을 위하여 회원 가입 시 입력한 이메일<span style='letter-spacing:1.4pt'> </span>주소나<span
style='letter-spacing:1.45pt'> </span>문자와<span style='letter-spacing:1.4pt'> </span>숫자,<span
style='letter-spacing:1.45pt'> </span>특수문자의<span style='letter-spacing:1.45pt'>
</span>등으로<span style='letter-spacing:1.4pt'> </span>이루어진<span
style='letter-spacing:1.45pt'> </span>고유한<span style='letter-spacing:1.45pt'> </span>정보를<span
style='letter-spacing:1.4pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>“비밀번호”라 함은 회사의 서비스를
이용하려는 사람이 회원 ID를 부여 받은 자와 동 일인임을 확인하고 회원의 정보를 보호하기 위하여 회원이 설정하고 회사가 승인한 문자,<span
style='letter-spacing:1.4pt'> </span>숫자,<span style='letter-spacing:1.4pt'> </span>특수문자<span
style='letter-spacing:1.45pt'> </span>등의<span style='letter-spacing:1.5pt'> </span>조합을<span
style='letter-spacing:1.45pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:7.9pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>“앱 잠금 비밀번호“란 회원이 서비스를 이용을 위해 모바일 어플리케이션
또는 웹 사 이트에<span style='letter-spacing:1.4pt'> </span>회원이<span style='letter-spacing:
1.5pt'> </span>직접<span style='letter-spacing:1.45pt'> </span>입력하는<span
style='letter-spacing:1.45pt'> </span>본인확인용<span style='letter-spacing:1.45pt'>
</span>개인식별번호를<span style='letter-spacing:1.45pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.05pt;margin-right:
5.85pt;margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>“계좌”라 함은, 은행, 저축은행, 금융투자회사 등 금융기관에 개설된
수신  또는 투자, 예탁<span style='letter-spacing:1.45pt'> </span>기능<span
style='letter-spacing:1.45pt'> </span>등을<span style='letter-spacing:1.5pt'> </span>제공하는<span
style='letter-spacing:1.4pt'> </span>계좌를<span style='letter-spacing:1.45pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:7.9pt;margin-right:5.9pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>“계좌정보”라 함은 특정 계좌에 기록된 입금, 출금에 관한 정보, 
잔액에 관한 정보, 투  자<span style='letter-spacing:1.45pt'> </span>내역에<span
style='letter-spacing:1.45pt'> </span>관한<span style='letter-spacing:1.5pt'> </span>정보<span
style='letter-spacing:1.45pt'> </span>및<span style='letter-spacing:1.45pt'> </span>해당<span
style='letter-spacing:1.45pt'> </span>금융기관이<span style='letter-spacing:1.45pt'>
</span>제공하는<span style='letter-spacing:1.5pt'> </span>포인트에<span
style='letter-spacing:1.4pt'> </span>관한<span style='letter-spacing:1.45pt'> </span>정보를<span
style='letter-spacing:1.4pt'> </span>말합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:0cm;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>“카드”라<span style='letter-spacing:
1.4pt'> </span>함은<span style='letter-spacing:1.5pt'> </span>직불카드,<span
style='letter-spacing:1.5pt'> </span>신용카드,<span style='letter-spacing:1.45pt'> </span>체크카드를<span
style='letter-spacing:1.45pt'> </span>포괄합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.55pt;margin-right:
5.9pt;margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>“카드사”라 함은 카드를 발행한 자를 의미하며, 은행, 금융투자업자,
신용카드업자 등 업종을<span style='letter-spacing:1.4pt'> </span>불문합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:0cm;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>“카드정보”라<span style='letter-spacing:
2.55pt'> </span>함은<span style='letter-spacing:2.6pt'> </span>회원의<span
style='letter-spacing:2.65pt'> </span>보유<span style='letter-spacing:2.65pt'> </span>“카드”<span
style='letter-spacing:2.6pt'> </span>내역에<span style='letter-spacing:2.55pt'> </span>관한<span
style='letter-spacing:2.65pt'> </span>정보,<span style='letter-spacing:2.6pt'> </span>카드별<span
style='letter-spacing:2.6pt'> </span>사용<span style='letter-spacing:2.6pt'> </span>내역에<span
style='letter-spacing:2.6pt'> </span>관한</span></p>

</div>

<span style='font-size:10.0pt;font-family:"맑은 고딕",sans-serif'><br clear=all
style='page-break-before:always'>
</span>

<div class=WordSection2>

<p class=MsoBodyText style='margin-top:4.75pt;margin-right:10.15pt;margin-bottom:
0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-indent:0cm;line-height:102%'>정보,
포인트의 내역에 관한 정보, 한도에 관한 정보, 결제예정금액에 관한 정보, 청구 서에 관한 정보, 승인내역에 관한 정보 등을 의미합니다.</p>

<p class=MsoListParagraph style='margin-right:5.8pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>본 조 및 본 약관의 다른 조항에서
정의한 것을 제외하고는 전자금융거래법 등 관련 법   령에 정한 바에<span style='letter-spacing:.85pt'> </span>따릅니다.</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 3조 (약관의 명시와 개정)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.9pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 이 약관을 이용자가 알 수
있도록 서비스가 제공되는 모바일 어플리케이션 및 웹 사이트에<span style='letter-spacing:1.45pt'> </span>게시합니다.</span></p>

<p class=MsoListParagraph style='margin-right:5.8pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 약관의 규제에 관한 법률,
전기통신기본법,  전기통신사업법,  정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 신용정보의 이용 및 보호에 관현 법률 등 
관련<span style='letter-spacing:1.45pt'> </span>법령을<span style='letter-spacing:
1.4pt'> </span>위배하지<span style='letter-spacing:1.45pt'> </span>않는<span
style='letter-spacing:1.5pt'> </span>범위에서<span style='letter-spacing:1.4pt'> </span>이<span
style='letter-spacing:1.5pt'> </span>약관을<span style='letter-spacing:1.45pt'> </span>개정할<span
style='letter-spacing:1.45pt'> </span>수<span style='letter-spacing:1.45pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사가 약관을 개정할 경우에는 개정
약관의 적용일자 및 개정사유를 명시하여  현행약관  과 함께 개정 약관의 적용일자 7 일전에 회원에게 공지합니다. 단 회원의 권리, 의무에 중대한<span
style='letter-spacing:1.4pt'> </span>영향을<span style='letter-spacing:1.5pt'> </span>주는<span
style='letter-spacing:1.45pt'> </span>변경의<span style='letter-spacing:1.5pt'> </span>경우에는<span
style='letter-spacing:1.4pt'> </span>적용일자<span style='letter-spacing:1.45pt'> </span>30<span
style='letter-spacing:1.4pt'> </span>일<span style='letter-spacing:1.5pt'> </span>전에<span
style='letter-spacing:1.45pt'> </span>공지하도록<span style='letter-spacing:1.45pt'>
</span>합니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.9pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원은 변경된 약관에 대해 거부할
권리가 있으며, 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있습니다.  회원이 변경된 약관을 거부하는  경우 본 서비스 제공자 
인 회사는 15일의 기간을 정하여 회원에게 사전통지 후 당해 회원과의 계약을 해지할 수 있습니다. 만약, 회원이 해당  기간 내에 거부 의사를
표시하지 않거나,  전항에 따라 시행   일<span style='letter-spacing:1.45pt'> </span>이후에<span
style='letter-spacing:1.45pt'> </span>서비스를<span style='letter-spacing:1.45pt'> </span>이용하는<span
style='letter-spacing:1.45pt'> </span>경우에는<span style='letter-spacing:1.5pt'> </span>변경된<span
style='letter-spacing:1.45pt'> </span>약관에<span style='letter-spacing:1.4pt'> </span>동의한<span
style='letter-spacing:1.4pt'> </span>것으로<span style='letter-spacing:1.45pt'> </span>간주합니다.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 4조 (이용계약의 체결)</h2>

<p class=MsoListParagraph style='margin-top:7.7pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>서비스 이용계약은, 회원이 되고자
하는 자(이하 &quot;가입신청자&quot;)가 회사에서 요구하는 정보  를 제공하고, 회사가 지정하는 방법에 따라 이 약관에 대한 동의 의사를
전자적 방식으로 제출한 후, 회사가 다음 각호의 요건을 확인하여 해당 가입신청자에게 이용을 승낙함으로  써<span
style='letter-spacing:1.45pt'> </span>체결됩니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:7.9pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>가입신청자는 본인 명의로 통신사에 가입된 휴대전화 또는 태블릿 등 티클앱을
구동    할<span style='letter-spacing:1.45pt'> </span>수<span style='letter-spacing:
1.45pt'> </span>있는<span style='letter-spacing:1.45pt'> </span>전자적<span
style='letter-spacing:1.45pt'> </span>장치를<span style='letter-spacing:1.5pt'> </span>보유하여야<span
style='letter-spacing:1.5pt'> </span>합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.05pt;margin-right:
5.8pt;margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>가입신청자는 반드시 신청자 본인의 명의로 가입 신청하여야  하며, 
본인확인기관 등  을<span style='letter-spacing:1.45pt'> </span>통하여<span
style='letter-spacing:1.45pt'> </span>실시하는<span style='letter-spacing:1.45pt'> </span>본인확인을<span
style='letter-spacing:1.45pt'> </span>통과하여야<span style='letter-spacing:1.45pt'>
</span>합니다.</span></p>

<p class=MsoListParagraph style='margin-right:5.9pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 회사가 제공하는 전자금융
서비스를 이용하기 위하여는 다음 각호의 요건을 추가   로<span style='letter-spacing:2.0pt'> </span>충족하여야<span
style='letter-spacing:2.0pt'> </span>합니다.<span style='letter-spacing:1.95pt'> </span>본<span
style='letter-spacing:2.0pt'> </span>항의<span style='letter-spacing:2.0pt'> </span>요건을<span
style='letter-spacing:2.0pt'> </span>충족하지<span style='letter-spacing:1.9pt'> </span>아니하는<span
style='letter-spacing:2.0pt'> </span>회원은<span style='letter-spacing:2.05pt'> </span>회사가<span
style='letter-spacing:1.9pt'> </span>제공하는<span style='letter-spacing:1.95pt'> </span>서비</span></p>

</div>

<span style='font-size:10.0pt;line-height:102%;font-family:"맑은 고딕",sans-serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection3>

<p class=MsoBodyText style='margin-top:4.75pt;text-indent:0cm'>스의 전부 또는 일부를 이용할
수 없거나 이용에 제한을 받을 수 있습니다.</p>

<p class=MsoListParagraph style='margin-top:8.5pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>가입신청자 본인 명의의 실명확인이
된, 정상적 입출금이 가능한 금융기관의 계좌를 보유하여야 하며, 회사가 정하는 방법에 따른 본인 계좌 인증을 통과하여야 합니다.  향후 회사는
서비스 제공 및 본인 인증 등을 위하여 금융기관 계좌가 아닌 다른 정보   나 인증수단을 요구할 수<span style='letter-spacing:
2.35pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:61.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>가입신청자는 제1호의 계좌에 관하여
회사에 대하여 이 약관 및 부속약관에 따라 추 심이체를 허용하는 출금동의를 제공하여야<span style='letter-spacing:
2.25pt'> </span>합니다.</span></p>

<p class=MsoBodyText style='margin-top:.9pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.0pt'>&nbsp;</span></p>

<h2>제 5조 (추심이체의 동의 및 철회)</h2>

<p class=MsoListParagraph style='margin-top:7.6pt;margin-right:5.9pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 추심이체의 동의를 함에 있어서는
회사가 제공하는 방식과 요건에 따른 전자서면 으로 동의를 제공하여야 합니다. 추심이체의 주요 조건은 이 약관의 부속약관의 내용을 따릅니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 전자금융감독규정에서 정한
요건에 부합하는 방식과 요건의 전자서면을 통한 동    의 방식을 제공하며, 추심이체의 실행을 위하여 회원으로부터 수령한 동의 사항을 <span
style='letter-spacing:-.1pt'>금융결 </span>제원<span style='letter-spacing:1.45pt'> </span>및<span
style='letter-spacing:1.45pt'> </span>담당<span style='letter-spacing:1.45pt'> </span>기관<span
style='letter-spacing:1.45pt'> </span>등에게<span style='letter-spacing:1.45pt'> </span>제출합니다.</span></p>

<p class=MsoListParagraph style='margin-right:5.75pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원은 티클앱을 통하여 제4조 제2항에
따라 회사에 등록된 계좌의 등록의 말소를 하는 방법으로 출금이체 동의를 철회할 수 있습니다. 다만, 회원은 동 철회의 의사표시 이전에 발생한<span
style='letter-spacing:1.45pt'> </span>출금건에<span style='letter-spacing:1.45pt'> </span>대하여<span
style='letter-spacing:1.4pt'> </span>이의를<span style='letter-spacing:1.45pt'> </span>제기할<span
style='letter-spacing:1.5pt'> </span>수<span style='letter-spacing:1.45pt'> </span>없습니다</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 6조 (이용계약의 거절 등)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 다음 각 호에 해당하는 가입신청에
대하여는 승낙을 하지 않거나, 승낙 이후라도 이용의<span style='letter-spacing:1.4pt'> </span>제한<span
style='letter-spacing:1.4pt'> </span>또는<span style='letter-spacing:1.45pt'> </span>이용계약의<span
style='letter-spacing:1.45pt'> </span>해지<span style='letter-spacing:1.45pt'> </span>등의<span
style='letter-spacing:1.5pt'> </span>조치를<span style='letter-spacing:1.45pt'> </span>취할<span
style='letter-spacing:1.5pt'> </span>수<span style='letter-spacing:1.45pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회사가<span style='letter-spacing:
1.45pt'> </span>정한<span style='letter-spacing:1.4pt'> </span>서비스<span
style='letter-spacing:1.5pt'> </span>제공환경이<span style='letter-spacing:1.45pt'> </span>아니거나<span
style='letter-spacing:1.45pt'> </span>기술상<span style='letter-spacing:1.45pt'> </span>서비스<span
style='letter-spacing:1.45pt'> </span>제공이<span style='letter-spacing:1.5pt'> </span>불가능한<span
style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>가입신청자가 신청시 제공한 정보에 허위, 누락이나 오류가 있거나, 회사가
요구  하는 기준을 충족하지 못하는<span style='letter-spacing:2.2pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>신청<span style='letter-spacing:
1.45pt'> </span>명의가<span style='letter-spacing:1.4pt'> </span>가입신청자<span
style='letter-spacing:1.45pt'> </span>본인의<span style='letter-spacing:1.45pt'> </span>명의가<span
style='letter-spacing:1.5pt'> </span>아닌<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>이미<span style='letter-spacing:
1.45pt'> </span>가입된<span style='letter-spacing:1.4pt'> </span>회원이<span
style='letter-spacing:1.45pt'> </span>중복하여<span style='letter-spacing:1.45pt'> </span>가입신청을<span
style='letter-spacing:1.45pt'> </span>하는<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.6pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>만<span style='letter-spacing:1.45pt'>
</span>16세<span style='letter-spacing:1.45pt'> </span>이하의<span
style='letter-spacing:1.5pt'> </span>자가<span style='letter-spacing:1.45pt'> </span>이용신청<span
style='letter-spacing:1.45pt'> </span>하는<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>법인 명의로 가입하는<span style='letter-spacing:
.75pt'> </span>경우</span></p>

</div>

<span style='font-size:10.0pt;font-family:"맑은 고딕",sans-serif'><br clear=all
style='page-break-before:always'>
</span>

<div class=WordSection4>

<p class=MsoListParagraph style='margin-top:4.75pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>기타 가입신청자의 귀책사유로 인하여
승낙이 불가능하거나 해당 가입신청이 법   령,<span style='letter-spacing:1.4pt'> </span>본<span
style='letter-spacing:1.45pt'> </span>약관<span style='letter-spacing:1.4pt'> </span>및<span
style='letter-spacing:1.5pt'> </span>기타<span style='letter-spacing:1.45pt'> </span>회사가<span
style='letter-spacing:1.5pt'> </span>정한<span style='letter-spacing:1.45pt'> </span>제반<span
style='letter-spacing:1.45pt'> </span>사항에<span style='letter-spacing:1.45pt'> </span>배치되는<span
style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 임의로 서비스를 해지한 후
재차 이용신청을 하는 경우 회사는 심사를 거쳐 당해 회원에<span style='letter-spacing:1.45pt'> </span>대하여<span
style='letter-spacing:1.5pt'> </span>일정기간<span style='letter-spacing:1.4pt'> </span>재가입을<span
style='letter-spacing:1.45pt'> </span>제한할<span style='letter-spacing:1.45pt'> </span>수<span
style='letter-spacing:1.45pt'> </span>있습니다.</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 7조 (서비스의 이용개시, 이용시간 및 수수료)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>제4조에 따라 회사가 신청인의 이용신청을
승낙함과 동시에 신청인은 회사가 제공하는 서비스의 회원이 되며, 이때부터 회사는 당해 회원에게 당해 약관이 정하는 서비스를 제 공합니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.9pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 본 약관이 정한 서비스 외에
추가적인 서비스를 제공하기 위해 회원에게 별도의 추가적인 약관 동의, 정보 수집 및 이용 동의 등 절차의 이행을 요청할 수 있으며, 이러한 절차가
완료되지 않는 경우 해당 회원이 추가적인 서비스의 전부 또는 일부를 이용하지   못할 수 있습니다. 회원이 추가 서비스를 이용할 경우, 각 서비스 
별로 추가되는 이용약관  과 본 약관의 내용이 상이한 경우 본 약관보다 추가적으로 적용되는 개별 약관이 우선 적 용됩니다</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>서비스의 이용은 회사의 업무상 또는
기술상의 특별한 지장이 없는 한 연중무휴, 1일 24 시간을 원칙으로 합니다. 다만, 서비스 운영상의 필요성으로 회사가 정한 기간 동안 본 서
비스가 일시 중지될 수 있으며, 회사와 연계하여 서비스를 제공하는 금융기관 등의 사유     로 서비스 중 일부 또는 전부가 중지될 수 있습니다.
서비스 중지가 발생하는 경우 그 내  용을<span style='letter-spacing:1.45pt'> </span>홈페이지<span
style='letter-spacing:1.5pt'> </span>또는<span style='letter-spacing:1.45pt'> </span>티클앱을<span
style='letter-spacing:1.45pt'> </span>통하여<span style='letter-spacing:1.4pt'> </span>공지합니다.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 8조 (서비스의 내용)</h2>

<p class=MsoListParagraph align=left style='margin-top:7.65pt;text-align:left'><span
style='font-size:10.0pt'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회사는 회원에게 아래의 서비스를<span
style='letter-spacing:2.2pt'> </span>제공합니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.55pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 티클앱을 통해 설정한 금액을
회원이 등록한 본인명의 계좌에서 출금하고, 상기 금액과 동일한 금액을 회원이 티클앱을 통해 생성한 본인명의 계좌로 입금  하는<span
style='letter-spacing:1.45pt'> </span>서비스</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>제1호의 서비스를 이행하는 과정에서,
회원이 티클앱을 통해 설정한 금액을 회원   이 등록한 본인명의 계좌에서 티클명의 계좌로 이체를 요청하고, 이체가 실행되는 시점 이전에 회사에서
상기 금액을 티클명의 계좌에서 회원이 티클앱을 통해 생  성한 본인명의 계좌로 선이체합니다. 이때, 선이체금에 대해 별도의 금리를 적용  하지<span
style='letter-spacing:1.45pt'> </span>않습니다.</span></p>

</div>

<span style='font-size:10.0pt;line-height:102%;font-family:"맑은 고딕",sans-serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection5>

<p class=MsoListParagraph style='margin-top:4.75pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원의 동의 하에 수집하거나 직접
입력한 정보를 확인할 수 있는 서비스: 신용/ 체크카드 사용 내역,  보유 계좌 잔액 및 입출금 내역,  보유 금융상품 정보, 기타  금융<span
style='letter-spacing:1.45pt'> </span>관련<span style='letter-spacing:1.45pt'> </span>정보<span
style='letter-spacing:1.5pt'> </span>및<span style='letter-spacing:1.45pt'> </span>기타<span
style='letter-spacing:1.45pt'> </span>개인정보<span style='letter-spacing:1.45pt'> </span>확인<span
style='letter-spacing:1.5pt'> </span>등</span></p>

<p class=MsoListParagraph align=left style='margin-left:77.0pt;text-align:left'><span
style='font-size:10.0pt'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>타 금융 연계 서비스의<span
style='letter-spacing:2.3pt'> </span>제공</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>기타<span style='letter-spacing:
1.45pt'> </span>서비스<span style='letter-spacing:1.4pt'> </span>이용을<span
style='letter-spacing:1.45pt'> </span>위한<span style='letter-spacing:1.4pt'> </span>제반<span
style='letter-spacing:1.45pt'> </span>관리용<span style='letter-spacing:1.45pt'> </span>서비스</span></p>

<p class=MsoBodyText style='margin-top:.6pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 9조 (서비스의 변경 및 중지)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 제9조 제1항 각호 상 서비스의
개선을 위하여 수시로 서비스의 전부 또는 일부 내용을 변경할 수 있습니다. 이 경우 회원은 변경될 서비스의 내용 및 변경 적용 일자를 홈페이지<span
style='letter-spacing:1.4pt'> </span>또는<span style='letter-spacing:1.5pt'> </span>티클앱<span
style='letter-spacing:1.45pt'> </span>등을<span style='letter-spacing:1.5pt'> </span>통해<span
style='letter-spacing:1.45pt'> </span>회원에게<span style='letter-spacing:1.45pt'> </span>공지합니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 다음 각 호에 해당하는 경우
서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:55.7pt;margin-bottom:.0001pt;text-align:left;
text-indent:-12.7pt;line-height:18.1pt'><span style='font-size:10.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt'>회사의<span style='letter-spacing:1.4pt'> </span>정보통신설비의<span
style='letter-spacing:1.4pt'> </span>보수점검,<span style='letter-spacing:1.45pt'> </span>교체<span
style='letter-spacing:1.4pt'> </span>및<span style='letter-spacing:1.45pt'> </span>고장,<span
style='letter-spacing:1.4pt'> </span>통신의<span style='letter-spacing:1.45pt'> </span>두절<span
style='letter-spacing:1.45pt'> </span>등의<span style='letter-spacing:1.4pt'> </span>사유가<span
style='letter-spacing:1.5pt'> </span>발생한<span style='letter-spacing:1.4pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:55.7pt;margin-bottom:.0001pt;text-align:left;
text-indent:-12.7pt'><span style='font-size:10.0pt'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>서비스를<span style='letter-spacing:
1.45pt'> </span>위한<span style='letter-spacing:1.45pt'> </span>설비의<span
style='letter-spacing:1.4pt'> </span>보수<span style='letter-spacing:1.45pt'> </span>등<span
style='letter-spacing:1.45pt'> </span>공사로<span style='letter-spacing:1.5pt'> </span>인해<span
style='letter-spacing:1.45pt'> </span>부득이한<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:.55pt;margin-right:0cm;
margin-bottom:0cm;margin-left:55.7pt;margin-bottom:.0001pt;text-align:left;
text-indent:-12.7pt'><span style='font-size:10.0pt'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>서비스<span style='letter-spacing:
1.4pt'> </span>업그레이드<span style='letter-spacing:1.45pt'> </span>및<span
style='letter-spacing:1.4pt'> </span>시스템<span style='letter-spacing:1.45pt'> </span>유지보수<span
style='letter-spacing:1.45pt'> </span>등을<span style='letter-spacing:1.45pt'> </span>위해<span
style='letter-spacing:1.5pt'> </span>필요한<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:.55pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;text-align:left;
text-indent:0cm;line-height:102%'><span style='font-size:10.0pt;line-height:
102%'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 
이용에 지장이  있는<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:55.7pt;margin-bottom:.0001pt;text-align:left;
text-indent:-12.7pt;line-height:18.1pt'><span style='font-size:10.0pt'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt'>가맹점과의<span style='letter-spacing:1.45pt'> </span>계약종료<span
style='letter-spacing:1.4pt'> </span>등<span style='letter-spacing:1.5pt'> </span>회사의<span
style='letter-spacing:1.45pt'> </span>제반<span style='letter-spacing:1.5pt'> </span>사정으로<span
style='letter-spacing:1.4pt'> </span>서비스를<span style='letter-spacing:1.45pt'> </span>유지할<span
style='letter-spacing:1.45pt'> </span>수<span style='letter-spacing:1.45pt'> </span>없는<span
style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:55.7pt;margin-bottom:.0001pt;text-align:left;
text-indent:-12.7pt'><span style='font-size:10.0pt'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>기타<span style='letter-spacing:
1.4pt'> </span>천재지변,<span style='letter-spacing:1.45pt'> </span>국가비상사태<span
style='letter-spacing:1.45pt'> </span>등<span style='letter-spacing:1.45pt'> </span>불가항력적<span
style='letter-spacing:1.45pt'> </span>사유가<span style='letter-spacing:1.45pt'> </span>있는<span
style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph style='margin-top:8.55pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>제2항에 의하여 본 서비스를 중단하는
경우 회사는 즉시 제1항에서 정한 방법으로 회원  에게 공지합니다. 다만, 회사가 통제할 수 없는 사유로 본 서비스가 중단(회사 또는 티클 서비스
운영자의 고의 및 과실이 없는 디스크 장애, 시스템 다운 등)되어 사전 공지가 불 가능한 경우에는 그러하지<span
style='letter-spacing:.85pt'> </span>아니합니다.</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 10조 (공지사항 및 광고성 및 맞춤형 정보의 제공)</h2>

<p class=MsoListParagraph align=left style='margin-top:7.6pt;text-align:left'><span
style='font-size:10.0pt'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회사는 서비스의 운영과 관련한 공지사항을 홈페이지 또는 티클앱
화면에<span style='letter-spacing:3.05pt'> </span>게재하거나</span></p>

<p class=MsoBodyText style='margin-top:.55pt;text-indent:0cm'>SMS, Push Message
등을 통하여 통지할 수 있습니다.</p>

<p class=MsoListParagraph style='margin-top:8.5pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 본 서비스의 운영과 관련한
광고성 정보 및 회원 맞춤형 정보를 홈페이지 또는 티클앱 화면에 게재하거나 SMS, Push Message 등의 방법으로 회원에게 제공할 수 있습
니다.<span style='letter-spacing:1.45pt'> </span>다만<span style='letter-spacing:
1.45pt'> </span>광고성<span style='letter-spacing:1.45pt'> </span>또는<span
style='letter-spacing:1.4pt'> </span>맞춤형<span style='letter-spacing:1.45pt'> </span>정보는<span
style='letter-spacing:1.5pt'> </span>사전에<span style='letter-spacing:1.45pt'> </span>수신에<span
style='letter-spacing:1.5pt'> </span>동의한<span style='letter-spacing:1.4pt'> </span>회원에게만<span
style='letter-spacing:1.45pt'> </span>제공됩니다.</span></p>

</div>

<span style='font-size:10.0pt;line-height:102%;font-family:"맑은 고딕",sans-serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection6>

<h2 style='margin-top:3.7pt'>제 11조 (이용의 제한 또는 정지)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원의 서비스 이용 중 다음 각
호의 사유가 발생하는 경우, 회사는 해당 회원의 서비스 이용을<span style='letter-spacing:1.45pt'> </span>제한<span
style='letter-spacing:1.4pt'> </span>또는<span style='letter-spacing:1.45pt'> </span>정지할<span
style='letter-spacing:1.4pt'> </span>수<span style='letter-spacing:1.5pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-left:77.0pt;text-align:left'><span
style='font-size:10.0pt'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>접근매체의<span style='letter-spacing:
1.45pt'> </span>도난<span style='letter-spacing:1.45pt'> </span>분실<span
style='letter-spacing:1.45pt'> </span>등<span style='letter-spacing:1.45pt'> </span>사고<span
style='letter-spacing:1.45pt'> </span>신고된<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:5.9pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로
서비 스를<span style='letter-spacing:1.45pt'> </span>이용하거나<span style='letter-spacing:
1.45pt'> </span>회사의<span style='letter-spacing:1.4pt'> </span>시스템에<span
style='letter-spacing:1.45pt'> </span>접근하는<span style='letter-spacing:1.45pt'> </span>행위</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:5.9pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>타인의 명의, 카드정보, 계좌정보 등을 도용하여 회사가 제공하는 서비스를
이용  하는<span style='letter-spacing:1.45pt'> </span>행위</span></p>

<p class=MsoListParagraph align=left style='margin-left:77.0pt;text-align:left'><span
style='font-size:10.0pt'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회원이<span style='letter-spacing:
1.4pt'> </span>회사의<span style='letter-spacing:1.5pt'> </span>서비스<span
style='letter-spacing:1.45pt'> </span>운영을<span style='letter-spacing:1.45pt'> </span>고의로<span
style='letter-spacing:1.4pt'> </span>방해하는<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.55pt;margin-right:
0cm;margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>다른<span style='letter-spacing:
1.4pt'> </span>회원의<span style='letter-spacing:1.4pt'> </span>정당한<span
style='letter-spacing:1.45pt'> </span>이익을<span style='letter-spacing:1.5pt'> </span>침해하거나<span
style='letter-spacing:1.45pt'> </span>법령에<span style='letter-spacing:1.45pt'> </span>위배되는<span
style='letter-spacing:1.45pt'> </span>행위를<span style='letter-spacing:1.5pt'> </span>한<span
style='letter-spacing:1.4pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.55pt;margin-right:
0cm;margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>본<span style='letter-spacing:1.45pt'>
</span>약관에<span style='letter-spacing:1.45pt'> </span>위배되는<span
style='letter-spacing:1.45pt'> </span>행위를<span style='letter-spacing:1.5pt'> </span>한<span
style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.55pt;margin-right:
0cm;margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>이<span style='letter-spacing:1.45pt'>
</span>약관에서<span style='letter-spacing:1.4pt'> </span>규정한<span
style='letter-spacing:1.4pt'> </span>사항이나<span style='letter-spacing:1.45pt'> </span>별도<span
style='letter-spacing:1.45pt'> </span>페이지에<span style='letter-spacing:1.4pt'> </span>규정한<span
style='letter-spacing:1.5pt'> </span>이용정책을<span style='letter-spacing:1.5pt'> </span>위반한<span
style='letter-spacing:1.4pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회원의<span style='letter-spacing:
1.4pt'> </span>계정에<span style='letter-spacing:1.5pt'> </span>대한<span
style='letter-spacing:1.45pt'> </span>채권추심,<span style='letter-spacing:1.45pt'>
</span>압류명령<span style='letter-spacing:1.5pt'> </span>등<span style='letter-spacing:
1.45pt'> </span>법원의<span style='letter-spacing:1.45pt'> </span>명령이<span
style='letter-spacing:1.4pt'> </span>있는<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left;
line-height:102%'><span style='font-size:10.0pt;line-height:102%'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>기타 회사가 합리적인 판단에 의해 서비스의 제공을 거부할 필요가 있다고
인정     할<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph style='margin-top:8.05pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 본 조의 금지행위를 하는
경우 서비스 이용을 제한함과 별도로 손해배상의 청구, 사법당국에<span style='letter-spacing:1.45pt'> </span>대한<span
style='letter-spacing:1.45pt'> </span>고발<span style='letter-spacing:1.45pt'> </span>등<span
style='letter-spacing:1.45pt'> </span>법적<span style='letter-spacing:1.45pt'> </span>조치를<span
style='letter-spacing:1.45pt'> </span>취할<span style='letter-spacing:1.45pt'> </span>수<span
style='letter-spacing:1.45pt'> </span>있습니다.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2 style='margin-top:.05pt'>제 12조 (분쟁처리담당자 및 이의신청)</h2>

<p class=MsoListParagraph style='margin-top:7.6pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원은 서비스 이용과 관련하여 회사에
이의가 있는 경우 서면으로 회사에 이의를 제기할   수 있습니다. 분쟁처리책임자는 아래와<span style='letter-spacing:
2.35pt'> </span>같습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-left:77.0pt;text-align:left'><span
style='font-size:10.0pt'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>분쟁처리책임자/담당자<span style='letter-spacing:
1.4pt'> </span>강상윤</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.6pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>담당<span style='letter-spacing:
1.45pt'> </span>강상윤</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:77.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>이메일<span style='letter-spacing:
1.4pt'> </span></span><a href="mailto:support@primavera.im"><span
style='font-size:10.0pt;color:windowtext;text-decoration:none'>support@primavera.im</span></a></p>

<p class=MsoListParagraph style='margin-top:8.5pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원은 제1항에 따라 서면(전자문서를
포함합니다) 또는 전자적 장치를 이용하여 회사의 본점이나 영업점에 분쟁처리를 신청할 수 있으며, 회사는 15일 이내에 이에 대한 조사 또   는
처리 결과를 회원에게<span style='letter-spacing:2.3pt'> </span>안내합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:0cm;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회원은<span style='letter-spacing:
2.75pt'> </span>회사의<span style='letter-spacing:2.75pt'> </span>분쟁처리결과에<span
style='letter-spacing:2.75pt'> </span>대하여<span style='letter-spacing:2.75pt'> </span>이의가<span
style='letter-spacing:2.75pt'> </span>있을<span style='letter-spacing:2.75pt'> </span>경우<span
style='letter-spacing:2.7pt'> </span>'금융위원회의<span style='letter-spacing:2.7pt'>
</span>설치<span style='letter-spacing:2.8pt'> </span>등에<span style='letter-spacing:
2.7pt'> </span>관한</span></p>

</div>

<span style='font-size:10.0pt;font-family:"맑은 고딕",sans-serif'><br clear=all
style='page-break-before:always'>
</span>

<div class=WordSection7>

<p class=MsoBodyText style='margin-top:4.75pt;margin-right:5.8pt;margin-bottom:
0cm;margin-left:41.0pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0cm;line-height:102%'>법률'에 따른 금융감독원의 금융분쟁조정위원회나 '소비자기본법'
에 따른 한국소비자원의 소비자분쟁조정위원회에 회사의 전자금융거래서비스의 이용과 관련한 분쟁조정을 신청할  수<span
style='letter-spacing:1.45pt'> </span>있습니다.</p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>금융감독원의 분쟁조정결과 이용과정에서
회사에 책임이 있는 사실이 밝혀질 경우 회사는 이용대금의 전액을 부담합니다. 다만 회원 또는 회사가 금융감독원의 분쟁조정결과에 불 복하여<span
style='letter-spacing:1.45pt'> </span>관할법원에<span style='letter-spacing:1.45pt'>
</span>민사소송을<span style='letter-spacing:1.45pt'> </span>제기하는<span
style='letter-spacing:1.45pt'> </span>경우에는<span style='letter-spacing:1.5pt'> </span>그러하지<span
style='letter-spacing:1.4pt'> </span>아니합니다.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 13조 (개인정보의 보호)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 관계법령이 정하는 바에 따라서
회원 입력정보를 포함한 회원의 개인정보를 보호 하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련 법령 및 회사의 개인정보 보호정책이
적용됩니다. 회원이 본 약관 및 개인정보취급방침, 개인정보수집이용,  개인정  보 제3자 제공,  개인정보 취급 위탁에 동의하는 것은 그에 정한
바에 따라 개인정보의 수  집,<span style='letter-spacing:1.4pt'> </span>이용,<span
style='letter-spacing:1.45pt'> </span>제공,<span style='letter-spacing:1.45pt'> </span>취급위탁,<span
style='letter-spacing:1.45pt'> </span>보호정책<span style='letter-spacing:1.45pt'> </span>등에<span
style='letter-spacing:1.5pt'> </span>동의하는<span style='letter-spacing:1.4pt'> </span>것으로<span
style='letter-spacing:1.45pt'> </span>봅니다.</span></p>

<p class=MsoListParagraph style='margin-right:5.75pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 이용자의 정보 수집 시 서비스
제공에 필요한 최소한의 정보를 수집하며 이용자     가 제공한 개인정보를 관계법령에 위배하여 타인에게 누설, 제공하여서는 안됩니다. 다만, 전기통신<span
style='letter-spacing:1.4pt'> </span>관련<span style='letter-spacing:1.5pt'> </span>법령<span
style='letter-spacing:1.45pt'> </span>등<span style='letter-spacing:1.45pt'> </span>관계법령에<span
style='letter-spacing:1.45pt'> </span>의하여<span style='letter-spacing:1.45pt'> </span>허용되는<span
style='letter-spacing:1.45pt'> </span>경우에는<span style='letter-spacing:1.5pt'> </span>그러하지<span
style='letter-spacing:1.4pt'> </span>아니합니다.</span></p>

<p class=MsoListParagraph style='margin-right:5.75pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>제공된 회원정보는 해당 회원의 동의
없이 본래 목적 외의 용도로 이용하거나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만, 다음의 경우에는 예외로 합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;text-align:left;
text-indent:0cm;line-height:102%'><span style='font-size:10.0pt;line-height:
102%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>상품배송(사은품, 가맹점 상품구매)과 관련하여 배송업체에게 배송에 필요한
최소한의 이용자의<span style='letter-spacing:1.4pt'> </span>정보(성명,<span
style='letter-spacing:1.45pt'> </span>주소,<span style='letter-spacing:1.45pt'> </span>전화번호)를<span
style='letter-spacing:1.45pt'> </span>알려주는<span style='letter-spacing:1.45pt'> </span>경우</span></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;text-align:left;
text-indent:0cm;line-height:102%'><span style='font-size:10.0pt;line-height:
102%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
식별할 수 없는 형태로 제공하는<span style='letter-spacing:.75pt'> </span>경우</span></p>

<p class=MsoListParagraph style='margin-top:7.9pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원은 언제든지 자신의 개인정보를
열람할 수 있고  회사 또는 정보관리책임자에게 잘못  된 정보에 대한 정정을 요청할 수 있습니다. 회사는 회원으로부터 정보 정정의 요청이 있  
는<span style='letter-spacing:1.45pt'> </span>경우,<span style='letter-spacing:
1.45pt'> </span>그<span style='letter-spacing:1.4pt'> </span>오류가<span
style='letter-spacing:1.45pt'> </span>시정될<span style='letter-spacing:1.5pt'> </span>때까지<span
style='letter-spacing:1.45pt'> </span>당해<span style='letter-spacing:1.5pt'> </span>개인정보를<span
style='letter-spacing:1.45pt'> </span>이용하지<span style='letter-spacing:1.4pt'> </span>않습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:52.1pt;margin-bottom:.0001pt;text-align:left;
text-indent:-9.1pt;line-height:18.1pt'><span style='font-size:10.0pt'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt'>개인정보관리<span style='letter-spacing:1.45pt'> </span>책임자</span></p>

<p class=MsoListParagraph align=left style='margin-top:.55pt;margin-right:0cm;
margin-bottom:0cm;margin-left:52.1pt;margin-bottom:.0001pt;text-align:left;
text-indent:-9.1pt'><span style='font-size:10.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>담당:<span style='letter-spacing:
1.4pt'> </span>강상윤</span></p>

<p class=MsoListParagraph align=left style='margin-top:.5pt;margin-right:0cm;
margin-bottom:0cm;margin-left:52.1pt;margin-bottom:.0001pt;text-align:left;
text-indent:-9.1pt'><span style='font-size:10.0pt'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>이메일:<span style='letter-spacing:
-.05pt'> </span></span><a href="mailto:support@primavera.im"><span
style='font-size:10.0pt;color:windowtext;text-decoration:none'>support@primavera.im</span></a></p>

<p class=MsoListParagraph style='margin-top:8.6pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(5)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 회원의 개인정보 보호를 위하여
관리자를 최소한으로 한정하며 회사의 고의 또는 과실로 인하여, 통장 계좌번호 등을 포함한 회원의 개인정보의 분실, 도난, 유출, 변조 등 으로<span
style='letter-spacing:1.45pt'> </span>야기된<span style='letter-spacing:1.4pt'> </span>회원의<span
style='letter-spacing:1.45pt'> </span>손해에<span style='letter-spacing:1.5pt'> </span>대하여는<span
style='letter-spacing:1.4pt'> </span>모든<span style='letter-spacing:1.45pt'> </span>책임을<span
style='letter-spacing:1.4pt'> </span>부담합니다.</span></p>

</div>

<span style='font-size:10.0pt;line-height:102%;font-family:"맑은 고딕",sans-serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection8>

<p class=MsoListParagraph style='margin-top:4.75pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(6)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사 또는 회사로부터 개인정보를
제공받은 제3자는 개인정보의 수집 목적이나 제공받은 목적을<span style='letter-spacing:1.4pt'> </span>달성한<span
style='letter-spacing:1.5pt'> </span>때에는<span style='letter-spacing:1.45pt'> </span>당해<span
style='letter-spacing:1.5pt'> </span>개인정보를<span style='letter-spacing:1.5pt'> </span>지체<span
style='letter-spacing:1.4pt'> </span>없이<span style='letter-spacing:1.45pt'> </span>파기합니다.</span></p>

<p class=MsoListParagraph style='margin-right:5.75pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(7)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사가 회원으로부터 개인정보의 제공에
관하여 동의를 받아야 하는 경우, 정보제공 관련 사항(제공받는 자, 제공목적, 제공정보의 내용)을 미리 명시하거나 고지하며, 회원은 일단 개인정보의<span
style='letter-spacing:1.45pt'> </span>제공에<span style='letter-spacing:1.45pt'> </span>동의하더라도<span
style='letter-spacing:1.45pt'> </span>언제든지<span style='letter-spacing:1.45pt'> </span>그<span
style='letter-spacing:1.45pt'> </span>동의를<span style='letter-spacing:1.45pt'> </span>철회할<span
style='letter-spacing:1.4pt'> </span>수<span style='letter-spacing:1.5pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(8)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>서비스 이외의 웹에서 링크된 사이트에서는
회사의 개인정보 보호정책이 적용되지 않습 니다. 또한 회사는 회원의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않 습니다.</span></p>

<p class=MsoBodyText style='margin-top:.85pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.0pt'>&nbsp;</span></p>

<h2>제 14조 (계약의 해지 등)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원은 언제든지 회사에 이용계약
해지의 의사를 통지함으로써 이용계약을 해지할 수 있 습니다. 단, 회원은 해지의사를 통지하기 전에 회사가 제공하는 서비스를 이용하여 진행중  인
제반 거래 행위를 완결하여 더 이상 서비스를 통하여 처리되어야 할 채권 채무 관계가 남지 않도록 하여야 하며, 이러한 조치를 취하지 아니하고 서비스의
이용 해지 의사표시     를 함으로써 발생하는 불이익에 대하여는 회원이 그 책임을 부담하여야 합니다. 회사가 필요하다고 판단하는 경우, 이와
같은 채권 채무 관계가 모두 해소될 때까지 해지의 효력 발생시기를 연기할 수<span style='letter-spacing:.8pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 임의 해지, 재가입 등을
반복적으로 행함으로써 회사가 제공하는 혜택이나 경제적 이익을 비통상적인 방법으로 악용할 우려가 있다고 회사가 판단하는 경우, 회사는  <span
style='letter-spacing:-.1pt'>재가입</span><span style='letter-spacing:3.3pt'> </span>을
제한할 수 있습니다. 이러한 심사 목적 등을 위하여 회사는 회원 탈퇴 시 관계법령이   정한 기간동안 동안 해당 회원의 성명, 탈퇴일자, 탈퇴사유
등 필요한 정보를 보관할 수 있습니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>이 약관에 따라 이용계약이 해지된
경우 회사는 회원에게 부가적으로 제공한 각종 혜택을 무효화 하거나 회수할 수<span style='letter-spacing:2.3pt'> </span>있습니다.</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 15조 (계좌정보 서비스의 제공)</h2>

<p class=MsoBodyText style='margin-top:7.65pt;margin-right:22.1pt;margin-bottom:
0cm;margin-left:5.0pt;margin-bottom:.0001pt;text-indent:0cm;line-height:102%'>회사는
제4조 제2항에 따라 티클앱에 계좌를 등록한 회원에 대하여, 해당 등록 계좌의 계좌정보     를<span style='letter-spacing:
1.45pt'> </span>열람할<span style='letter-spacing:1.45pt'> </span>수<span
style='letter-spacing:1.45pt'> </span>있도록<span style='letter-spacing:1.45pt'> </span>하는<span
style='letter-spacing:1.45pt'> </span>서비스(“계좌정보<span style='letter-spacing:
1.45pt'> </span>서비스”)를<span style='letter-spacing:1.45pt'> </span>제공합니다.</p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 16조 (카드정보 서비스의 제공)</h2>

</div>

<span style='font-size:11.0pt;font-family:"맑은 고딕",sans-serif'><br clear=all
style='page-break-before:always'>
</span>

<div class=WordSection9>

<p class=MsoListParagraph style='margin-top:4.75pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 본 약관에 따라 보유 카드의
카드번호 등 회사가 요청하는 정보를 제공한 회원에 대하여, 티클앱에 등록한 카드의 카드정보를 회원이 열람할 수 있도록 하는 서비스(“카드 정보
서비스”)를<span style='letter-spacing:-.65pt'> </span>제공합니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 제1항 각호의 정보를 요청함에
있어, 카드사별로 서로 다른 정보를 요청할 수 있 습니다.<span style='letter-spacing:1.4pt'> </span>회원은<span
style='letter-spacing:1.5pt'> </span>티클앱을<span style='letter-spacing:1.4pt'> </span>통하여<span
style='letter-spacing:1.45pt'> </span>카드사별<span style='letter-spacing:1.45pt'> </span>필요한<span
style='letter-spacing:1.4pt'> </span>정보의<span style='letter-spacing:1.4pt'> </span>내역을<span
style='letter-spacing:1.5pt'> </span>확인할<span style='letter-spacing:1.45pt'> </span>수<span
style='letter-spacing:1.45pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 “카드정보 서비스”의 원활한
제공을 위하여, 회원의 티클앱이 설치된 단말기로 수 신한 카드 사용 내역에 관한 메세지들 중 필요한 정보를 추출, 가공하여 회원에게 티클앱   
을 통하여 일괄하여 열람하도록 할 수 있습니다. 단, 동항의 서비스는 단말기의 메시지를 통한<span style='letter-spacing:
1.45pt'> </span>정보<span style='letter-spacing:1.45pt'> </span>수집에<span
style='letter-spacing:1.45pt'> </span>동의한<span style='letter-spacing:1.4pt'> </span>회원에<span
style='letter-spacing:1.45pt'> </span>한하여<span style='letter-spacing:1.5pt'> </span>제공됩니다.</span></p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 17조 (손해배상)</h2>

<p class=MsoListParagraph style='margin-top:7.65pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사가 제공하는 서비스의 하자 등에
의하여 회원에게 피해가 발생한 경우, 회사는 회원     에 대하여 손해를 배상하여야<span style='letter-spacing:
2.3pt'> </span>합니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 이 약관의 의무를 위반함으로
인하여 회사에 손해를 입힌 경우 또는 회원이 서비  스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은 회사에 대하여 손해를 배상하여야 합니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원이 서비스를 이용함에 있어 행한
불법행위 또는 본 약관을 위반한 행위로 회사가 당    해 회원 외의 제 3 자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당     
해 회원 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우(관    련 법령에 따라 책임에 대한 제한이나 면제가 허용되지
않는 경우 등) 당해 회원은 그로 인하여<span style='letter-spacing:1.4pt'> </span>회사에<span
style='letter-spacing:1.5pt'> </span>발생한<span style='letter-spacing:1.45pt'> </span>모든<span
style='letter-spacing:1.5pt'> </span>손해를<span style='letter-spacing:1.45pt'> </span>배상할<span
style='letter-spacing:1.45pt'> </span>책임이<span style='letter-spacing:1.4pt'> </span>있습니다.</span></p>

<p class=MsoBodyText style='margin-top:.55pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:19.0pt'>&nbsp;</span></p>

<h2 style='margin-top:.05pt'>제 18조 (대리 및 보증의 부인)</h2>

<p class=MsoListParagraph style='margin-top:7.6pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회원과 제3자와의 거래에 있어서
회사는 회원이나 제3자를 대리하지 않습니다. 회사가 관   련 서비스의 개별 이용약관 등을 통하여 달리 명시하지 아니하는 한, 회사의 어떠한 행위   
도<span style='letter-spacing:1.5pt'> </span>제3자나<span style='letter-spacing:
1.4pt'> </span>회원을<span style='letter-spacing:1.45pt'> </span>대리하는<span
style='letter-spacing:1.45pt'> </span>행위로<span style='letter-spacing:1.4pt'> </span>간주되지<span
style='letter-spacing:1.45pt'> </span>않습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사가 티클앱을 통하여 제3자의
서비스를 이용할 수 있도록 하는 경우, 해당  서비스 제 공의 주체는 그 제3자이며, 회사는 그 제3자가 제공하는 정보를 회원에게 전달되도록 하   
는 도구를 제공합니다. 제3자가 제공하는 서비스는 티클앱을 통하여 관련 추가 약관 등에 대한<span style='letter-spacing:
1.45pt'> </span>동의절차를<span style='letter-spacing:1.45pt'> </span>거친<span
style='letter-spacing:1.45pt'> </span>회원에<span style='letter-spacing:1.45pt'> </span>한하여<span
style='letter-spacing:1.4pt'> </span>이용할<span style='letter-spacing:1.45pt'> </span>수<span
style='letter-spacing:1.45pt'> </span>있습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-left:41.0pt;text-align:left'><span
style='font-size:10.0pt'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회사는<span style='letter-spacing:
1.85pt'> </span>서비스를<span style='letter-spacing:1.9pt'> </span>통하여<span
style='letter-spacing:1.85pt'> </span>교환되는<span style='letter-spacing:1.85pt'> </span>회원과<span
style='letter-spacing:1.9pt'> </span>제3자간의<span style='letter-spacing:1.95pt'> </span>정보와<span
style='letter-spacing:1.85pt'> </span>관련하여<span style='letter-spacing:1.9pt'> </span>회원<span
style='letter-spacing:1.85pt'> </span>또는<span style='letter-spacing:1.9pt'> </span>제3자의</span></p>

</div>

<span style='font-size:10.0pt;font-family:"맑은 고딕",sans-serif'><br clear=all
style='page-break-before:always'>
</span>

<div class=WordSection10>

<p class=MsoBodyText style='margin-top:4.75pt;margin-right:5.75pt;margin-bottom:
0cm;margin-left:41.0pt;margin-bottom:.0001pt;text-align:justify;text-justify:
inter-ideograph;text-indent:0cm;line-height:102%'>거래의사의 존부 및 진정성, 제3자가 제공하는 상품의
품질, 완전성, 안전성, 적법성, 정보    의 진실성, 타인의 권리에 대한 비침해성, 제3자와 회원이 제공하는 정보의 진실성 또는 적법성에 대하여
일체 보증하지<span style='letter-spacing:2.25pt'> </span>아니합니다.</p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 19조 (지식재산권)</h2>

<p class=MsoListParagraph align=left style='margin-top:7.65pt;text-align:left'><span
style='font-size:10.0pt'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회사의<span style='letter-spacing:
1.45pt'> </span>서비스와<span style='letter-spacing:1.45pt'> </span>관련된<span
style='letter-spacing:1.4pt'> </span>일체의<span style='letter-spacing:1.45pt'> </span>지식재산권은<span
style='letter-spacing:1.45pt'> </span>회사에게<span style='letter-spacing:1.45pt'> </span>속합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.55pt;margin-right:
5.8pt;margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>(2)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>회원은 본 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 본
서비스에 게재된 자료를<span style='letter-spacing:1.45pt'> </span>영리<span
style='letter-spacing:1.4pt'> </span>목적으로<span style='letter-spacing:1.45pt'> </span>이용하거나<span
style='letter-spacing:1.45pt'> </span>제3자에게<span style='letter-spacing:1.45pt'>
</span>이용하게<span style='letter-spacing:1.45pt'> </span>할<span style='letter-spacing:
1.45pt'> </span>수<span style='letter-spacing:1.45pt'> </span>없습니다.</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 20조 (면책사항)</h2>

<p class=MsoListParagraph align=left style='margin-top:7.65pt;margin-right:
5.9pt;margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>(1)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 본 서비스를 제공할
수 없는 경 우에는<span style='letter-spacing:1.45pt'> </span>본<span style='letter-spacing:
1.45pt'> </span>서비스<span style='letter-spacing:1.45pt'> </span>제공에<span
style='letter-spacing:1.4pt'> </span>관한<span style='letter-spacing:1.45pt'> </span>책임이<span
style='letter-spacing:1.4pt'> </span>면제됩니다.</span></p>

<p class=MsoListParagraph align=left style='text-align:left'><span
style='font-size:10.0pt'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>회사는<span style='letter-spacing:
1.4pt'> </span>회원의<span style='letter-spacing:1.45pt'> </span>귀책사유로<span
style='letter-spacing:1.45pt'> </span>인한<span style='letter-spacing:1.45pt'> </span>서비스<span
style='letter-spacing:1.45pt'> </span>이용의<span style='letter-spacing:1.4pt'> </span>장애에<span
style='letter-spacing:1.4pt'> </span>대하여는<span style='letter-spacing:1.5pt'> </span>책임을<span
style='letter-spacing:1.4pt'> </span>지지<span style='letter-spacing:1.45pt'> </span>않습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.55pt;margin-right:5.7pt;
margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>회사는 회원 상호간, 회원과 가맹점
상호간 또는 회원과 제3자 상호간에 본 서비스를 매  개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임이 없 습니다.</span></p>

<p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:18.5pt'>&nbsp;</span></p>

<h2>제 21조 (관할법윈)</h2>

<p class=MsoListParagraph align=left style='margin-top:7.65pt;margin-right:
5.9pt;margin-bottom:0cm;margin-left:43.0pt;margin-bottom:.0001pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>(1)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와
회원은 분쟁의 해결을 위해 성실히<span style='letter-spacing:.75pt'> </span>협의합니다.</span></p>

<p class=MsoListParagraph align=left style='margin-right:5.75pt;text-align:
left;line-height:102%'><span style='font-size:10.0pt;line-height:102%'>(2)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:10.0pt;line-height:102%'>제1항의 협의에서도 분쟁이 해결되지 않아 소송이 제기되는 경우에는 서울중앙지방법원  
을 1심 관할법원으로<span style='letter-spacing:.85pt'> </span>합니다.</span></p>

<p class=MsoBodyText style='margin-top:.7pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:19.0pt'>&nbsp;</span></p>

<h2>제 22조 (이 약관에서 정하지 아니한 사항)</h2>

<p class=MsoBodyText style='margin-top:7.65pt;margin-right:10.15pt;margin-bottom:
0cm;margin-left:5.0pt;margin-bottom:.0001pt;text-indent:0cm;line-height:102%'>이
약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자금융거래기본약관, 관계법령  또는 상관례에<span
style='letter-spacing:-.6pt'> </span>따릅니다</p>

</div>

<span style='font-size:11.0pt;line-height:102%;font-family:"맑은 고딕",sans-serif'><br
clear=all style='page-break-before:always'>
</span>

<div class=WordSection11>

<h1 align=center style='margin-top:3.45pt;margin-right:185.7pt;margin-bottom:
0cm;margin-left:184.9pt;margin-bottom:.0001pt;text-align:center'>-부칙-</h1>

<p class=MsoBodyText style='margin-top:7.65pt;margin-right:0cm;margin-bottom:
0cm;margin-left:5.0pt;margin-bottom:.0001pt;text-indent:0cm'>이 약관은 2019년 06월 10일부터
시행됩니다.</p>

<p class=MsoBodyText style='margin:0cm;margin-bottom:.0001pt;text-indent:0cm'>&nbsp;</p>

<p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:6.5pt'>&nbsp;</span></p>

</div>

<span style='font-size:6.5pt;font-family:"맑은 고딕",sans-serif'><br clear=all
style='page-break-before:auto'>
</span>

<div class=WordSection12>

<p class=MsoBodyText style='margin-top:.3pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:19.5pt'>&nbsp;</span></p>

<h2>금융기관 출금이체 약관</h2>

<span style='font-size:11.0pt;font-family:"맑은 고딕",sans-serif'><br clear=all>
</span>

<p class=MsoNormal style='margin-top:3.8pt;margin-right:0cm;margin-bottom:0cm;
margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:14.0pt;
font-family:AppleSDGothicNeoH00'>-부속약관-</span></p>

</div>

<span style='font-size:14.0pt;font-family:AppleSDGothicNeoH00'><br clear=all
style='page-break-before:auto'>
</span>

<div class=WordSection13>

<p class=MsoBodyText style='margin-top:.55pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;text-indent:0cm'><span
style='font-size:2.5pt;font-family:AppleSDGothicNeoH00'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:2.6pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>본인(회원이 수납기관에 등록한 계좌의
계좌주)이 납부하여야 할 요금에 대하여 금융기관 앞으로 청구가 있는 경우에는 별도의 통지없이 본인의 지정출금계좌에서 수납기관의 요청  에 따라
즉시 출금대체납부하여<span style='letter-spacing:2.3pt'> </span>주십시오</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.85pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>전항의 출금을 하는 경우에는 금융기관과의
금융거래 약관이나 약정서의 규정에도 불구하  고<span style='letter-spacing:1.45pt'> </span>청구서나<span
style='letter-spacing:1.4pt'> </span>또는<span style='letter-spacing:1.45pt'> </span>수표<span
style='letter-spacing:1.45pt'> </span>없이<span style='letter-spacing:1.5pt'> </span>출금이체<span
style='letter-spacing:1.4pt'> </span>처리절차에<span style='letter-spacing:1.4pt'> </span>의하여<span
style='letter-spacing:1.45pt'> </span>출금하여도<span style='letter-spacing:1.45pt'>
</span>이의가<span style='letter-spacing:1.5pt'> </span>없습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.0pt;margin-right:5.95pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>출금일이 동일한 수종의 출금이체
청구가 있을 경우 출금 우선순위는 금융기관에서 임의   로 지정하여도 이의가<span style='letter-spacing:.9pt'> </span>없습니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.9pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>출금이체 신규신청에 의한 이체개시일은
금융기관 및 수납기관의 사정에 의해 결정되며, 수납기관으로부터<span style='letter-spacing:1.4pt'> </span>사전<span
style='letter-spacing:1.45pt'> </span>통지<span style='letter-spacing:1.5pt'> </span>받은<span
style='letter-spacing:1.45pt'> </span>청구일을<span style='letter-spacing:1.45pt'> </span>최초<span
style='letter-spacing:1.45pt'> </span>개시일로<span style='letter-spacing:1.45pt'> </span>하겠습니다.</span></p>

<p class=MsoListParagraph style='margin-top:8.05pt;margin-right:5.8pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(5)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>출금이체에 의한 지정계좌에서의 출금은
수납기관의 청구대로 출금키로 하며, 청구금액에 이의가<span style='letter-spacing:1.45pt'> </span>있을<span
style='letter-spacing:1.4pt'> </span>경우에는<span style='letter-spacing:1.45pt'> </span>본인과<span
style='letter-spacing:1.45pt'> </span>수납기관이<span style='letter-spacing:1.45pt'>
</span>협의하여<span style='letter-spacing:1.5pt'> </span>조정합니다.</span></p>

<p class=MsoListParagraph style='margin-top:7.95pt;margin-right:5.75pt;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;line-height:102%'><span
style='font-size:10.0pt;line-height:102%'>(6)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;line-height:102%'>본 출금이체 신청에 대하여 분쟁이
발생하는 경우, 금융기관의 귀책사유가 없는 한 본인     의 책임으로<span style='letter-spacing:-.65pt'> </span>하겠습니다.</span></p>

<p class=MsoListParagraph align=left style='margin-top:8.0pt;margin-right:0cm;
margin-bottom:0cm;margin-left:41.0pt;margin-bottom:.0001pt;text-align:left'><span
style='font-size:10.0pt'>(7)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>이<span style='letter-spacing:1.45pt'>
</span>약관은<span style='letter-spacing:1.45pt'> </span>신청서를<span
style='letter-spacing:1.45pt'> </span>수납기관에<span style='letter-spacing:1.45pt'>
</span>직접<span style='letter-spacing:1.4pt'> </span>제출하여<span style='letter-spacing:
1.45pt'> </span>출금이체를<span style='letter-spacing:1.45pt'> </span>신청한<span
style='letter-spacing:1.4pt'> </span>경우에도<span style='letter-spacing:1.5pt'> </span>적용합니다.</span></p>

</div>

</body>

</html>
`