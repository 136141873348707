import React from 'react';

import Fade from 'react-reveal/Fade';

import Container from '@material-ui/core/Container';

import cardSite from "../../assets/image/illust/passbookSite.png";
import ticklehistory from "../../assets/image/pieGuide.png";

import './Spare.css';

export default function Pie() {

  return (
    <div className="introContainer white mobileReverse">
      <Container style={{ display: "flex"}} className="columnInMobileReverse">
          <div className="spareHalf">
            <Fade bottom distance="80px">
              <img src={ticklehistory} className="intro-mockup" alt="ticklehistory"/>
            </Fade>
          </div>
          <div className="spareHalf">
            <Fade bottom distance="80px" delay={200} className="introTexts">
              <img src={cardSite} className="titleIcon" />
              <p className="introTitle">적금처럼 <b>자동</b>으로</p>
              <span className="introContent">
                내가 원하는 기간과 금액으로<br></br>
                자동 투자돼요!
              </span>
            </Fade>
          </div>
      </Container>
    </div>
  );
}
