import React from 'react';

import Fade from 'react-reveal/Fade';

import Container from '@material-ui/core/Container';

import cardSite from "../../assets/image/illust/challengePiggySite@2x.png";
import ticklehistory from "../../assets/image/moneyChallenge@2x.png";

import './Spare.css';

export default function Divide() {

  return (
    <div className="introContainer gray">
      <Container style={{ display: "flex"}} className="columnInMobile">
          <div className="spareHalf">
            <Fade bottom distance="80px" delay={200} className="introTexts">
              <img src={cardSite} className="titleIcon" />
              <p className="introTitle">적금처럼 <b>꾸준</b>하게</p>
              <span className="introContent">
                원하는 목표를 세우고<br></br>
                꾸준히 달성해봐요!
              </span>
            </Fade>
          </div>
          <div className="spareHalf">
            <Fade bottom distance="80px">
              <img src={ticklehistory} className="intro-mockup" alt="ticklehistory"/>
            </Fade>

          </div>
      </Container>
    </div>
  );
}
