import React from 'react';
import styled from "styled-components";
import Fade from 'react-reveal/Fade';
import { isMobile } from "react-device-detect";
import ReactPixel from 'react-facebook-pixel';

import giftSite from '../../assets/image/gift-site.png'

const Reservation = () => {
  const onClick = () => {
    window.location.href = '/promotion/earlybird_overseas_stock'
    window.airbridge.events.send("waitlist_section");
    ReactPixel.track("waitlist_section");

  }
  return (
    <Contianer>
      <Fade bottom distance="80px">
        <Title>
          지금 예약하면 {isMobile? (<br></br>) : null }<b>해외주식</b>을 드려요
        </Title>
        <ContentContainer>
          {isMobile? (
            <Content>
              예약하면 해외주식 상품권 10,000원을<br></br>
              드려요! 해외주식 서비스가 오픈되면<br></br>
              가장 먼저 연락할게요
            </Content>  
          ): (
            <Content>
              예약하면 해외주식 상품권 10,000원을 드려요<br></br>
              해외주식 서비스가 오픈되면 가장 먼저 연락할게요
            </Content> 
          )}
        </ContentContainer>
        <GiftImage src={giftSite} />
        <ReservationButton onClick={onClick}>
          <ButtonText>
            예약하러 가기
        </ButtonText>
        </ReservationButton>
      </Fade>
    </Contianer>
  )
}

const Contianer = styled.div`
  background-color: #ffa23b;
  padding: 80px 0px;
  text-align: center;
`

const Title = styled.span`
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 24px;
  @media  (min-width: 320px) and (max-width: 767px) {
    font-size: 21px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  text-align: center;
  @media  (min-width: 320px) and (max-width: 767px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const Content = styled.p`
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 48px;
`

const GiftImage = styled.img`
  width: 167px;
  margin-bottom: 48px;
`

const ReservationButton = styled.button`
  display: block;
  background-color: #ffffff;
  padding: 14px 116px;
  border-radius: 10px;
  border-width: 0px;
  margin: 0 auto;
  cursor: pointer;
`

const ButtonText = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: #ff9016;
`;


export default Reservation;