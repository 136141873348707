import React from 'react';
import RecruitList from '../components/RecruitList';
import AppBar from '../components/AppBar'

function Recruit() {
  return (
    <div className="root">
      <AppBar orange />
      <RecruitList />
    </div>
  );
}

export default Recruit;
