module.exports = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Helvetica;
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:굴림;
	panose-1:2 11 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"맑은 고딕";
	panose-1:2 11 5 3 2 0 0 2 0 4;}
@font-face
	{font-family:"맑은 고딕";}
@font-face
	{font-family:"굴림";
	panose-1:2 11 6 0 0 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
h5
	{mso-style-link:"제목 5 Char";
	margin-right:0cm;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"굴림",sans-serif;
	font-weight:bold;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"메모 텍스트 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"머리글 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	layout-grid-mode:char;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"바닥글 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	layout-grid-mode:char;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:purple;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"굴림",sans-serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"메모 주제 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"풍선 도움말 텍스트 Char";
	margin:0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-justify:inter-ideograph;
	text-autospace:none;
	word-break:break-hangul;
	font-size:9.0pt;
	font-family:"맑은 고딕",sans-serif;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-justify:inter-ideograph;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
span.Char
	{mso-style-name:"머리글 Char";
	mso-style-link:머리글;}
span.Char0
	{mso-style-name:"바닥글 Char";
	mso-style-link:바닥글;}
span.Char1
	{mso-style-name:"메모 텍스트 Char";
	mso-style-link:"메모 텍스트";}
span.Char2
	{mso-style-name:"메모 주제 Char";
	mso-style-link:"메모 주제";
	font-weight:bold;}
span.Char3
	{mso-style-name:"풍선 도움말 텍스트 Char";
	mso-style-link:"풍선 도움말 텍스트";
	font-family:"맑은 고딕",sans-serif;}
p.pty1de2h, li.pty1de2h, div.pty1de2h
	{mso-style-name:pty1_de2h;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"굴림",sans-serif;}
span.5Char
	{mso-style-name:"제목 5 Char";
	mso-style-link:"제목 5";
	font-family:"굴림",sans-serif;
	font-weight:bold;}
p.a, li.a, div.a
	{mso-style-name:바탕글;
	margin:0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:160%;
	layout-grid-mode:char;
	background:white;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"굴림",sans-serif;
	color:black;}
.MsoChpDefault
	{font-family:"맑은 고딕",sans-serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.9pt 2.0cm 70.9pt 2.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=KO link=blue vlink=purple>

<div class=WordSection1>

<table class=MsoTableGrid border=0 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr style='height:42.5pt'>
  <td width=643 style='width:481.9pt;border-top:solid gray 0pt;border-left:
  none;border-bottom:solid gray 0pt;border-right:none;padding:0cm 5.4pt 0cm 5.4pt;
  height:42.5pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><b><span style='font-size:18.0pt'>개인정보처리방침<span
  lang=EN-US>(</span>티클<span lang=EN-US>_</span>주식회사 티클<span lang=EN-US>)</span></span></b></p>
  </td>
 </tr>
</table>

<p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-size:
12.0pt;color:black;letter-spacing:-.25pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:19.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='color:black;letter-spacing:-.25pt'>‘</span><span
style='color:black;letter-spacing:-.25pt'>티클<span lang=EN-US>’</span>을 운영하는 주식회사
티클<span lang=EN-US>(</span>이하<span lang=EN-US> '</span>회사<span lang=EN-US>')</span>은
정보통신망 이용촉진 및 정보보호 등에 관한 법률<span lang=EN-US>(</span>이하 <span lang=EN-US>‘</span>정보통신망법<span
lang=EN-US>’), </span>개인정보보호법<span lang=EN-US>, </span>신용정보의 이용 및 보호에 관한 법률 등 관련
법에 따라 이용자의 개인<span lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 보호 및 권익을 보호하고
개인<span lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보와 관련한 이용자의 고충을 원활하게 처리할
수 있도록 다음과 같은 처리방침을 두고 있습니다<span lang=EN-US>. </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"굴림",sans-serif;color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 개인정보처리방침을 개정하는
경우 어플리케이션 내 공지사항을 통하여 공지합니다<span lang=EN-US>.  </span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:19.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>본 방침은<span
lang=EN-US> 2019</span>년 <span lang=EN-US>06</span>월 <span lang=EN-US>10</span>일
부터 시행됩니다<span lang=EN-US>.  </span></span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>1. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인<span lang=EN-US>(</span>신용<span
lang=EN-US>)</span>정보 수집 목적 및 항목</span></b></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"굴림",sans-serif;color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 다음의 목적을 위해 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 처리합니다<span lang=EN-US>. </span></span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=645
 style='margin-left:5.4pt;border-collapse:collapse;border:none'>
 <tr style='height:16.15pt'>
  <td width=106 valign=top style='width:79.65pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:16.15pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>목적</span></b></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:16.15pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>구분</span></b></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:16.15pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>필수항목</span></b></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:none;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:16.15pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>선택항목</span></b></p>
  </td>
 </tr>
 <tr style='height:3.0pt'>
  <td width=106 rowspan=2 valign=top style='width:79.65pt;border-top:none;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:3.0pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>서비스 회원가입</span></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:3.0pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>회원가입</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:3.0pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'>이름<span lang=EN-US>, </span>휴대폰번호<span
  lang=EN-US>, </span>생년월일<span lang=EN-US>, </span>성별</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:3.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:11.55pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>본인인증 및 카드연동 </span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>이름<span lang=EN-US>, </span>휴대폰번호<span
  lang=EN-US>, </span>생년월일<span lang=EN-US>, </span>성별<span lang=EN-US>, </span>이동통신사
  정보<span lang=EN-US>, </span>고객 보유 신용카드사<span lang=EN-US>, </span>신용카드사 웹사이트 접속용<span
  lang=EN-US> ID/</span>비밀번호<span lang=EN-US>, </span>카드번호<span lang=EN-US>, </span>유효기간<span
  lang=EN-US>, CVC, </span>비밀번호<span lang=EN-US>, </span>휴대폰 기기고유번호<span
  lang=EN-US> ( IDFV / Android_ID )</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:19.45pt'>
  <td width=106 rowspan=2 valign=top style='width:79.65pt;border-top:none;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.45pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;vertical-align:baseline'><span
  style='color:black;letter-spacing:-.25pt'>회원관리</span></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.45pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>부정거래기록 관리</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.45pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>이름<span lang=EN-US>, </span>휴대폰번호</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:11.55pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>조사업무<span lang=EN-US>(</span>만족도조사<span lang=EN-US>, </span>설문조사<span
  lang=EN-US>, </span>통계분석 등<span lang=EN-US>)</span></span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>휴대폰번호</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:11.55pt'>
  <td width=106 rowspan=3 valign=top style='width:79.65pt;border-top:none;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;vertical-align:baseline'><span
  style='color:black;letter-spacing:-.25pt'>재화 또는 <span lang=EN-US><br>
  </span>서비스의 제공</span></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>카드승인내역조회</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>보유카드<span lang=EN-US>, </span>결제계좌<span
  lang=EN-US>, </span>카드이용일시<span lang=EN-US>, </span>승인번호<span lang=EN-US>, </span>이용카드<span
  lang=EN-US>, </span>이용가맹점<span lang=EN-US>, </span>이용금액<span lang=EN-US>, </span>승인상태
  등 승인내역<span lang=EN-US>, </span>신용카드사 웹사이트 접속용<span lang=EN-US> ID/</span>비밀번호</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:11.55pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>자동이체 등록</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>이름<span lang=EN-US>, </span>생년월일<span
  lang=EN-US>, </span>휴대폰번호<span lang=EN-US>, </span>본인 명의의 출금용 계좌번호<span
  lang=EN-US>/</span>금융기관명</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:11.55pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>서비스 품질향상 및 상품 개발</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>생년월일<span lang=EN-US>, </span>성별<span
  lang=EN-US>, </span>서비스 이용 기록<span lang=EN-US>, </span>이동통신사 정보</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:11.55pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:69.7pt'>
  <td width=106 rowspan=3 valign=top style='width:79.65pt;border-top:none;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;vertical-align:baseline'><span
  style='color:black;letter-spacing:-.25pt'>미래에셋대우 비대면 <span lang=EN-US>CMA </span>계좌개설</span></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>미래에셋대우 비대면 <span
  lang=EN-US>CMA </span>계좌개설</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>이름<span
  lang=EN-US>, </span>생년월일<span lang=EN-US>, </span>성별<span lang=EN-US>, </span>휴대폰
  번호<span lang=EN-US>, </span>휴대폰 통신사<span lang=EN-US>, </span>본인 명의의 출금용 계좌번호<span
  lang=EN-US>/</span>금융기관명</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:42.75pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:42.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>미래에셋대우<span
  lang=EN-US> CMA </span>계좌개설 여부 인증</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:42.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>미래에셋대우 계좌번호<span lang=EN-US>, </span>미래에셋대우
  계좌 개설 일자</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:42.75pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>&nbsp;</span></p>
  </td>
 </tr>
 <tr style='height:69.7pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>고객 식별 및 미래에셋대우 계좌
  잔고 조회 등 계좌 조회</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>미래에셋대우 계좌번호<span lang=EN-US>, </span>미래에셋대우
  계좌 개설 일자<span lang=EN-US>, </span>자산총액<span lang=EN-US>, </span>예수금<span
  lang=EN-US>, </span>수익률<span lang=EN-US>, </span>수익금<span lang=EN-US>, </span>거래내역<span
  lang=EN-US>, </span>미래에셋대우 홈페이지 아이디<span lang=EN-US>/</span>비밀번호</p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:69.7pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:18.75pt'>
  <td width=106 rowspan=2 valign=top style='width:79.65pt;border-top:none;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>이용자 민원 및 고충 처리</span></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>회원일 경우</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>이름<span lang=EN-US>, </span>이메일<span lang=EN-US>, </span>휴대폰번호</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:18.75pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>비회원일 경우</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>이메일</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:18.75pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:16.7pt'>
  <td width=106 rowspan=3 valign=top style='width:79.65pt;border-top:none;
  border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>마케팅 및 이벤트 활용</span></p>
  </td>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>마케팅 정보 수신</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>이름<span lang=EN-US>, </span>휴대폰번호<span lang=EN-US>, </span>생년월일<span
  lang=EN-US>, </span>성별</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:16.7pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>오프라인 마케팅</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>휴대폰번호</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
 <tr style='height:16.7pt'>
  <td width=161 valign=top style='width:120.5pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='background:white'>블로그</span><span style='font-family:Helvetica;
  background:white'> </span><span style='background:white'>등</span><span
  style='font-family:Helvetica;background:white'> </span><span
  style='background:white'>온라인</span><span style='font-family:Helvetica;
  background:white'> </span><span style='background:white'>마케팅</span><span
  style='font-family:Helvetica;background:white'> </span><span
  style='background:white'>진행</span></p>
  </td>
  <td width=302 valign=top style='width:8.0cm;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;word-break:keep-all'><span
  style='color:black'>휴대폰번호</span></p>
  </td>
  <td width=76 valign=top style='width:56.65pt;border:none;border-bottom:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.7pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span lang=EN-US style='color:black'>-</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:3.3pt;margin-bottom:.0001pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='color:
black;letter-spacing:-.25pt'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사가 처리하는 회원정보의 목적과
항목이 변경될 경우에는 관련법령에 따라 사전에 동의를 요청합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;line-height:
115%;color:black;letter-spacing:-.25pt'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:12.0pt;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;line-height:
115%;color:black;letter-spacing:-.25pt'>2. </span></b><b><span
style='font-size:12.0pt;line-height:115%;color:black;letter-spacing:-.25pt'>개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보의 보유 및 이용 기간</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>관련법령에 따른 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 보유·이용기간 또는 정보주체로부터 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 수집 시에 동의 받은 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 보유·이용기간 내에서 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 보유·이용합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>각각의 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 처리 및 보유 기간은 다음과 같습니다<span
lang=EN-US>.</span></span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=645
 style='width:483.85pt;margin-left:5.4pt;border-collapse:collapse;border:none'>
 <thead>
  <tr style='height:.3pt'>
   <td width=275 valign=top style='width:206.5pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>보유 목적</span></b></p>
   </td>
   <td width=57 valign=top style='width:42.7pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>기간</span></b></p>
   </td>
   <td width=313 valign=top style='width:234.65pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:none;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>보유 근거</span></b></p>
   </td>
  </tr>
 </thead>
 <tr style='height:.7pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>거래내역 및 증빙서류와 관련된 정보</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>5</span><span
  style='color:black'>년</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>국세기본법<span
  lang=EN-US>, </span>법인세법</span></p>
  </td>
 </tr>
 <tr style='height:1.25pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:1.25pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>표시<span lang=EN-US>•</span>광고에 관한 기록</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:1.25pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>6</span><span
  style='color:black'>개월</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:1.25pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>전자상거래 등에서의 소비자보호에
  관한 법률</span></p>
  </td>
 </tr>
 <tr style='height:.7pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>계약 또는 청약철회 등에 관한 기록</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>5</span><span
  style='color:black'>년</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:.7pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>전자상거래 등에서의 소비자보호에
  관한 법률</span></p>
  </td>
 </tr>
 <tr style='height:1.2pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:1.2pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>대금결제 및 재화 등의 공급에 관한 기록</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:1.2pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>5</span><span
  style='color:black'>년</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:1.2pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>전자상거래 등에서의 소비자보호에
  관한 법률</span></p>
  </td>
 </tr>
 <tr style='height:2.5pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>소비자의 불만 또는 분쟁처리에 관한 기록</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>3</span><span
  style='color:black'>년</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>전자상거래 등에서의 소비자보호에
  관한 법률</span></p>
  </td>
 </tr>
 <tr style='height:1.25pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:1.25pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>접속에 관한 기록보존</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:1.25pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>3</span><span
  style='color:black'>개월</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:1.25pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>통신비밀보호법 </span></p>
  </td>
 </tr>
 <tr style='height:2.5pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>상법 등 관련법령에 따라 보존할 필요성이 있는 경우</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>10</span><span
  style='color:black'>년</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>상법</span></p>
  </td>
 </tr>
 <tr style='height:2.5pt'>
  <td width=275 valign=top style='width:206.5pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black;
  letter-spacing:-.25pt'>부정거래기록</span></p>
  </td>
  <td width=57 valign=top style='width:42.7pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>5</span><span
  style='color:black'>년</span></p>
  </td>
  <td width=313 valign=top style='width:234.65pt;border:none;border-bottom:
  solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:2.5pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>회사 내부 방침</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>3. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인<span lang=EN-US>(</span>신용<span
lang=EN-US>)</span>정보의 처리 위탁 </span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 원활한 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 업무처리를 위하여 다음과 같이 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 처리업무를 위탁하고 있습니다<span
lang=EN-US>.</span></span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=642
 style='width:481.7pt;margin-left:5.4pt;border-collapse:collapse;border:none'>
 <tr style='height:.3pt'>
  <td width=172 style='width:129.3pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black'>수탁자</span></b></p>
  </td>
  <td width=272 style='width:203.85pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black'>위탁업무</span></b></p>
  </td>
  <td width=198 valign=top style='width:148.55pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:none;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>연락처</span></b></p>
  </td>
 </tr>
 <tr style='height:.65pt'>
  <td width=172 valign=top style='width:129.3pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span lang=EN-US
  style='color:black'>(</span><span style='color:black'>주<span lang=EN-US>)</span>케이에스넷</span></p>
  </td>
  <td width=272 valign=top style='width:203.85pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>출금이체 서비스 제공<span lang=EN-US>, </span>출금동의
  확인<span lang=EN-US>, </span>출금이체 신규등록<span lang=EN-US>, </span>해지사실 통지</p>
  </td>
  <td width=198 valign=top style='width:148.55pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>1544
  – 4700(6030)</span></p>
  </td>
 </tr>
 <tr style='height:.65pt'>
  <td width=172 valign=top style='width:129.3pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span lang=EN-US
  style='color:black'>AWS Pinpoint</span></p>
  </td>
  <td width=272 valign=top style='width:203.85pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>문자 메시지 발송 및 마케팅
  데이터 분석<span lang=EN-US>(</span>마케팅 분석 시 개인정보 이용 안함<span lang=EN-US>)</span></span></p>
  </td>
  <td width=198 valign=top style='width:148.55pt;border:none;border-bottom:
  solid #7F7F7F 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>aws-korea-privacy@amazon.com</span></p>
  </td>
 </tr>
 <tr style='height:.65pt'>
  <td width=172 valign=top style='width:129.3pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span lang=EN-US
  style='color:black;letter-spacing:-.25pt'>Amazon Web Service</span></p>
  </td>
  <td width=272 valign=top style='width:203.85pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>클라우드 서비스를 이용한 시스템
  운영 및 데이터 보관</span></p>
  </td>
  <td width=198 valign=top style='width:148.55pt;border:none;border-bottom:
  solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>aws-korea-privacy@amazon.com</span></p>
  </td>
 </tr>
</table>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 위탁 계약 체결 시 관련법령에
따라 수탁자가 개인<span lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 안전하게 처리하는지를 감독하고
있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>위탁업무의 내용이나 수탁자가 추가<span
lang=EN-US>, </span>변경될 경우에는 지체 없이 관련 법령에 따른 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록
하겠습니다<span lang=EN-US>.  </span></span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>4. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인<span lang=EN-US>(</span>신용<span
lang=EN-US>)</span>정보의 국외 이전</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 국외의 다른 사업자에게 제공하지 않습니다<span
lang=EN-US>. </span>다만<span lang=EN-US>, </span>정보통신서비스의 제공에 관한 계약 이행 및 이용자 편의 증진
등을 위하여 다음과 같이 개인정보 처리 업무를 국외에 위탁하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNoSpacing><span lang=EN-US> </span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=642
 style='width:481.7pt;margin-left:5.4pt;border-collapse:collapse;border:none'>
 <thead>
  <tr style='height:.3pt'>
   <td width=87 style='width:65.5pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black'>항목</span></b></p>
   </td>
   <td width=94 style='width:70.85pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>목적</span></b></p>
   </td>
   <td width=132 style='width:99.25pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>업체 및 국가</span></b></p>
   </td>
   <td width=151 style='width:4.0cm;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>일시 및 방법</span></b></p>
   </td>
   <td width=177 style='width:132.7pt;border-top:solid windowtext 1.0pt;
   border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:none;
   background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
   <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
   text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
   vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
   letter-spacing:-.25pt'>보유기간</span></b></p>
   </td>
  </tr>
 </thead>
 <tr style='height:.65pt'>
  <td width=87 valign=top style='width:65.5pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black'>이름<span
  lang=EN-US>, </span>휴대폰번호</span></p>
  </td>
  <td width=94 valign=top style='width:70.85pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>문자 메시지 발송</span></p>
  </td>
  <td width=132 valign=top style='width:99.25pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>AWS
  Pinpoint </span></p>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span lang=EN-US style='color:black'>/ </span><span
  style='color:black'>미국</span></p>
  </td>
  <td width=151 valign=top style='width:4.0cm;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>개인정보 항목 입력하여 마케팅
  정보 발신 시 네트워크를 통해 전송</span></p>
  </td>
  <td width=177 valign=top style='width:132.7pt;border:none;border-bottom:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>회원가입 탈퇴 시<span
  lang=EN-US>/</span>마케팅동의 철회 시 혹은 </span></p>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>위탁 계약 종료 시 까지</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>5. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인<span lang=EN-US>(</span>신용<span
lang=EN-US>)</span>정보의 제<span lang=EN-US>3</span>자 제공에 관한 사항</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 정보주체의 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 <span lang=EN-US>‘1. </span>개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 수집 목적 및 항목<span lang=EN-US>’</span>에서
명시한 범위 내에서만 처리하며<span lang=EN-US>, </span>정보주체의 동의<span lang=EN-US>, </span>법률의
특별한 규정 등 개인정보보호법 제<span lang=EN-US>17</span>조에 해당하는 경우에만 개인정보를 제<span
lang=EN-US>3</span>자에게 제공합니다<span lang=EN-US>. </span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 다음과 같이 개인정보를 제<span
lang=EN-US>3</span>자에게 제공하고 있습니다<span lang=EN-US>. </span></span></p>

<p class=MsoNoSpacing><span lang=EN-US>&nbsp;</span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=642
 style='width:481.7pt;margin-left:5.4pt;border-collapse:collapse;border:none'>
 <tr style='height:.3pt'>
  <td width=135 style='width:100.95pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>제공받는 자</span></b></p>
  </td>
  <td width=128 style='width:95.8pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>제공목적</span></b></p>
  </td>
  <td width=247 style='width:185.4pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>제공항목</span></b></p>
  </td>
  <td width=133 valign=top style='width:99.55pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid #7F7F7F 1.0pt;border-right:none;
  background:#D9D9D9;padding:0cm 5.4pt 0cm 5.4pt;height:.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><b><span style='color:black;
  letter-spacing:-.25pt'>보유 및 이용기간</span></b></p>
  </td>
 </tr>
 <tr style='height:.65pt'>
  <td width=135 valign=top style='width:100.95pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black'>고객 보유 신용카드사</span></p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span lang=EN-US
  style='color:black'>(</span><span style='color:black'>하나카드<span lang=EN-US>, </span>신한카드<span
  lang=EN-US>,</span></span></p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span lang=EN-US
  style='color:black'> </span><span style='color:black'>국민카드<span lang=EN-US>, </span>비씨카드<span
  lang=EN-US>, </span>현대카드 <span lang=EN-US>)</span></span></p>
  </td>
  <td width=128 valign=top style='width:95.8pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>본인 인증 및 카드 연동</span></p>
  </td>
  <td width=247 valign=top style='width:185.4pt;border-top:none;border-left:
  none;border-bottom:solid #7F7F7F 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>이름<span
  lang=EN-US>, </span>휴대폰번호<span lang=EN-US>, </span>생년월일<span lang=EN-US>, </span>성별<span
  lang=EN-US>, </span>이동통신사 정보<span lang=EN-US>, </span>고객 보유 신용카드사<span
  lang=EN-US>, </span>신용카드사 웹사이트 접속용<span lang=EN-US> ID/</span>비밀번호<span
  lang=EN-US>, </span>카드번호<span lang=EN-US>, </span>유효기간<span lang=EN-US>, CVC,
  </span>비밀번호<span lang=EN-US>, </span>휴대폰 기기고유번호</span></p>
  </td>
  <td width=133 valign=top style='width:99.55pt;border:none;border-bottom:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'><span style='color:black'>카드 연동 해지 또는 회원탈퇴
  시</span></p>
  </td>
 </tr>
 <tr style='height:.65pt'>
  <td width=135 valign=top style='width:100.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:center;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
  vertical-align:baseline;word-break:keep-all'><span style='color:black'>미래에셋대우</span></p>
  </td>
  <td width=128 valign=top style='width:95.8pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>미래에셋대우<span lang=EN-US> CMA </span>계좌 개설</p>
  </td>
  <td width=247 valign=top style='width:185.4pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid #7F7F7F 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>이름<span lang=EN-US>, </span>생년월일<span
  lang=EN-US>, </span>성별<span lang=EN-US>, </span>휴대폰 번호<span lang=EN-US>, </span>휴대폰
  통신사<span lang=EN-US>, </span>본인 명의의 출금용 계좌번호<span lang=EN-US>/</span>금융기관명</p>
  </td>
  <td width=133 valign=top style='width:99.55pt;border:none;border-bottom:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:.65pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;margin-bottom:.0001pt;
  text-align:left;line-height:normal'>서비스 해지 또는 회원탈퇴 시</p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 이용자의 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 사전동의 없이 제<span lang=EN-US> 3</span>자에게
제공할 수 없습니다<span lang=EN-US>. </span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:21.3pt;margin-bottom:.0001pt;line-height:17.0pt;text-autospace:
ideograph-numeric ideograph-other;vertical-align:baseline;word-break:keep-all'><span
style='color:black;letter-spacing:-.25pt'>다만 다음의 경우에는 예외로 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>통계작성<span
lang=EN-US>, </span>학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>관계법령에 의하여 국가기관으로부터
요구 받은 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>범죄에 대한 수사상의 목적이 있거나<span
lang=EN-US>, </span>정보통신 윤리위원회의 요청이 있는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>기타 관계법령에서 정한 절차에 따른
요청이 있는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>6. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>이용자의 권리와 의무 및 그 행사방법</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>만<span
lang=EN-US> 14</span>세 미만 아동의 개인<span lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를
수집하지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자는 등록되어 있는 이용자 본인의
개인정보 관련하여<span lang=EN-US>, </span>다음과 같은 권리를 행사할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자는 서면<span
lang=EN-US>, </span>전자우편<span lang=EN-US>, </span>어플리케이션 내 환경설정 <span
lang=EN-US>– </span>문의하기 등을 통하여 개인정보 처리 정지 및 삭제를 요구 할 수 있습니다<span lang=EN-US>. </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자가 개인정보의 오류 등에 대한
정정 또는 삭제를 요청한 경우에는 정정 또는 삭제를 완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보의 정정 및 삭제 요구는
다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자 권리 행사<span
lang=EN-US>(</span>열람<span lang=EN-US>, </span>정정<span lang=EN-US>, </span>처리정지<span
lang=EN-US>, </span>삭제<span lang=EN-US>)</span>는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
하실 수 있습니다<span lang=EN-US>. </span>이 경우 개인정보보호법 시행규칙 별지 제<span lang=EN-US>11</span>호
서식에 따른 위임장을 제출해야 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보주체 권리에 따른 열람의
요구<span lang=EN-US>, </span>정정<span lang=EN-US>·</span>삭제의 요구<span lang=EN-US>,
</span>처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><span lang=EN-US style='font-family:"굴림",sans-serif;
color:#666666;letter-spacing:-.25pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자는 개인정보주체로서 다음과
같은 의무를 가집니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자는 자신의 개인정보를 최신의
상태로 유지해야 하며<span lang=EN-US>, </span>부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자는 계정 등에 대해 보안을
유지할 책임이 있으며 제<span lang=EN-US>3</span>자에게 이를 양도하거나 대여할 수 없습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:#7F7F7F;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>타인의 개인정보를 도용하여 서비스
신청 시 이용자격 상실과 함께 관계법령에 의거하여 처벌될 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNoSpacing><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>7. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인<span lang=EN-US>(</span>신용<span
lang=EN-US>)</span>정보의 파기</span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Symbol;color:black;letter-spacing:-.25pt'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 원칙적으로 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 처리목적이 달성된 경우에는 지체 없이 해당 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보를 파기합니다<span lang=EN-US>. </span>파기의
절차 및 방법은 다음과 같습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>파기절차 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자가 입력한 정보는 목적 달성
후 즉시 파기합니다<span lang=EN-US>. </span>다만<span lang=EN-US>, 1</span>년간 서비스를 이용하지 않은
회원은 이를 사전에 고지하고<span lang=EN-US>, </span>이용자의 별도의 요청이 없는 경우 이용고객정보와 분리하여 안전하게 별도
보관합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>파기방법 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>전자적 파일 형태의 정보는 기록을
재생할 수 없는 기술적 방법을 사용합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>종이에 출력된 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보는 파쇄기를 통하여 파기합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>8. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항</span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Symbol;color:black;letter-spacing:-.25pt'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>서비스 이용과정이나 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 처리과정에서 다음과 같은 정보들이 생성<span
lang=EN-US>/</span>수집되고 다음의 목적으로 이용될 수 있습니다<span lang=EN-US>. </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>자동생성정보 <span
lang=EN-US>: </span>방문일시<span lang=EN-US>, </span>서비스 이용기록<span lang=EN-US>, </span>접속<span
lang=EN-US>IP</span>정보<span lang=EN-US>, </span>접속 로그</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>자동생성정보의 사용목적<span
lang=EN-US>: </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자에게 최적화된 정보 제공<span
lang=EN-US>         </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인 맞춤형 상품 광고 및 마케팅</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>서비스 제공 관련 고객상담<span
lang=EN-US>, </span>민원처리 등의 목적</span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>9. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인정보보호책임자 안내 </span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Symbol;color:black;letter-spacing:-.25pt'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 개인<span
lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 처리에 관한 업무를 총괄해서 책임지고<span
lang=EN-US>, </span>개인<span lang=EN-US>(</span>신용<span lang=EN-US>)</span>정보 처리와
관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다<span lang=EN-US>. </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보보호 책임자 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='letter-spacing:-.25pt'>성명<span lang=EN-US>: </span>김선형</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='letter-spacing:-.25pt'>직책<span lang=EN-US>: CTO</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>연락처<span
lang=EN-US>: official@primavera.im </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:45.8pt;margin-bottom:.0001pt;text-align:left;
line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;vertical-align:
baseline;word-break:keep-all'><span lang=EN-US style='color:black;letter-spacing:
-.25pt'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Symbol;color:black;letter-spacing:-.25pt'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자는 회사 서비스를 이용하면서
발생한 모든 개인정보보호 관련 문의<span lang=EN-US>, </span>불만처리<span lang=EN-US>, </span>피해구제
등에 관한 사항을 개인정보 보호책임자로 문의하실 수 있습니다<span lang=EN-US>. </span>회사는 이용자의 문의에 대해 지체 없이
답변 및 처리해드릴 것입니다<span lang=EN-US>. </span></span></p>

<p class=MsoNoSpacing><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNoSpacing><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>10. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인정보의 안전성 확보 조치</span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Symbol;color:black;letter-spacing:-.25pt'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 개인정보보호법 제<span
lang=EN-US>29</span>조에 따라 다음과 같이 안전성 확보에 필요한 기술적<span lang=EN-US>, </span>관리적 및
물리적 조치를 하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보 취급 직원의 최소화 및
교육 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보를 취급하는 직원을 최소화하고<span
lang=EN-US>, </span>주기적인 개인정보 보호 교육을 실시하여 개인정보를 관리하는 대책을 시행하고 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>내부관리계획의 수립 및 시행 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보의 안전한 처리를 위하여
내부관리계획을 수립하고 시행하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>접속기록의 보관 및 위변조 방지</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보 침해사고 발생 시 대응이
용이하도록 개인정보처리시스템에 접속한 기록<span lang=EN-US>(</span>웹 로그<span lang=EN-US>, </span>요약정보
등<span lang=EN-US>)</span>을 최소 <span lang=EN-US>1</span>년 이상 보관<span
lang=EN-US>, </span>관리하고 있으며<span lang=EN-US>, </span>접속 기록이 위변조 및 도난<span
lang=EN-US>, </span>분실되지 않도록 보안기능을 사용하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.75pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.75pt'>개인정보의 암호화</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이용자의 개인정보는 암호화 되어
저장 및 관리되고 있습니다<span lang=EN-US>. </span>또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을
사용하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>해킹 등에 대비한 기술적 대책 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>회사는 해킹이나 컴퓨터 바이러스
등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신<span lang=EN-US>·</span>점검을 하며<span
lang=EN-US>, </span>외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적<span lang=EN-US>/</span>물리적으로
감시 및 차단하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보에 대한 접근 제한 </span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보를 처리하는 개인정보처리시스템에
대한 접근권한의 부여<span lang=EN-US>, </span>변경<span lang=EN-US>, </span>말소를 통하여 개인정보에 대한
접근통제를 위한 조치를 하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>11. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>정보주체의 권익침해에 대한 구제방법</span></b></p>

<ul style='margin-top:0cm' type=disc>
 <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
     text-align:left;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
     vertical-align:baseline;word-break:keep-all'><span style='letter-spacing:
     -.25pt'>정보주체의 권익침해 관련 자세한 도움이 필요하시면 아래 기관에 문의하여 주시기 바랍니다<span lang=EN-US>.</span></span></li>
</ul>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보 침해신고센터<span
lang=EN-US> (</span>한국인터넷진흥원 운영<span lang=EN-US>)</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>소관업무<span
lang=EN-US> : </span>개인정보 침해사실 신고<span lang=EN-US>, </span>상담 신청</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>홈페이지<span
lang=EN-US> : privacy.kisa.or.kr     </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>전화<span
lang=EN-US> : (</span>국번없이<span lang=EN-US>) 118           </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>주소<span
lang=EN-US> : (58324) </span>전남 나주시 진흥길<span lang=EN-US> 9(</span>빛가람동<span
lang=EN-US> 301-2) 3</span>층 개인정보침해신고센터</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>개인정보 분쟁조정위원회<span
lang=EN-US>  </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>소관업무<span
lang=EN-US> : </span>개인정보 분쟁조정신청<span lang=EN-US>, </span>집단분쟁조정<span
lang=EN-US> (</span>민사적 해결<span lang=EN-US>)</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>홈페이지<span
lang=EN-US> : www.kopico.go.kr   </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>전화<span
lang=EN-US> : (</span>국번없이<span lang=EN-US>) 1833-6972   </span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:63.8pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Wingdings;color:black;letter-spacing:-.25pt'>§<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>주소<span
lang=EN-US> : (03171) </span>서울특별시 종로구 세종대로<span lang=EN-US> 209 </span>정부서울청사<span
lang=EN-US> 4</span>층</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>대검찰청 사이버범죄수사단<span
lang=EN-US> : 02-3480-3573 (www.spo.go.kr)</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:42.55pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:17.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
"Courier New";color:black;letter-spacing:-.25pt'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>경찰청 사이버안전국<span
lang=EN-US> : 182 (cyberbureau.police.go.kr)</span></span></p>

<p class=MsoNormal align=left style='margin-top:30.0pt;margin-right:0cm;
margin-bottom:7.5pt;margin-left:0cm;text-align:left;line-height:17.0pt;
text-autospace:ideograph-numeric ideograph-other;vertical-align:baseline;
word-break:keep-all'><b><span lang=EN-US style='font-size:12.0pt;color:black;
letter-spacing:-.25pt'>12. </span></b><b><span style='font-size:12.0pt;
color:black;letter-spacing:-.25pt'>개인정보처리방침 변경에 관한 사항</span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.3pt;margin-bottom:.0001pt;text-align:left;
text-indent:-18.0pt;line-height:19.0pt;text-autospace:ideograph-numeric ideograph-other;
vertical-align:baseline;word-break:keep-all'><span lang=EN-US style='font-family:
Symbol;color:black;letter-spacing:-.25pt'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black;letter-spacing:-.25pt'>이 개인정보처리방침은 시행일로부터
적용되며<span lang=EN-US>, </span>관련법령 및 방침에 따른 변경내용의 추가<span lang=EN-US>, </span>삭제
및 정정이 있는 경우에는 어플리케이션 내 공지사항을 통하여 고지할 것입니다<span lang=EN-US>.</span></span></p>

</div>

</body>

</html>
`