import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import { isIOS } from "react-device-detect";
import MetaTags from "react-meta-tags";

import PlanYesLandingPage from "../../assets/image/promotion/PlanYesLandingPage.png";

const PlanYes = (props) => {
  const handleDetailButton = () => {
    ReactGA.event({
      category: "PlanYesDetail",
      action: "click",
    });
    if (window.ReactNativeWebView){
      const data = {
        type: "sendEvent",
        title: "register_plan_yes_card",
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data))
    }
    window.location = "https://www.shinhancard.com/pconts/html/card/apply/check/1199344_2206.html?EntryLoc2=3073&empSeq=501&btnApp=dp1";
  };

  return (
    <>
      <MetaTags>
        <title>잔돈 재테크 끝판왕 "더모아 재테크"⎜티클</title>
        <meta name="description" content="지금 18,000포인트 받고 시작해볼까?" />
        <meta
          property="og:title"
          content={`잔돈 재테크 끝판왕 "더모아 재테크"⎜티클`}
        />
        <meta
          property="og:image"
          content="http://tickleinvest.com/invite-illust-piggy-light-orange.jpg" // TODO: 썸네일 이미지 변경 필요
        />
      </MetaTags>
      <Container>
        <FullImage src={PlanYesLandingPage} />
      </Container>
      <Button onClick={handleDetailButton}>
        <ButtonText>카드 혜택 더 보기</ButtonText>
      </Button>
      <input id="copyText" type="hidden" readOnly />
    </>
  );
};

const Container = styled.div`
  background-color: white;
  text-align: center;
  padding-bottom: 84px;
`;

const FullImage = styled.img`
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
  max-width: 768px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Button = styled.button`
  width: calc(100% - 40px);
  height: 50px;
  position: fixed;
  bottom: 34px;
  background-color: #ff9016;
  border-radius: 10px;
  border: 0;
  margin-left: 20px;
`;
const ButtonText = styled.span`
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
`;
const ButtonContainer = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 768px) {
    width: calc(100% - 40px);
  }
  max-width: 688px;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
`;
const OutlineButton = styled.button`
  width: 100%;
  height: 50px;
  border: 1px solid #ff9016;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const OutlineButtonText = styled.span`
  color: #ff9016;
  font-size: 15px;
  font-weight: bold;
`;
export default PlanYes;
