import React, { useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios'
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
import qs from 'query-string'
import ReactPixel from 'react-facebook-pixel';
import Drawer from '@material-ui/core/Drawer';

const AES_PASS = 'tickle1234';
const mapper = ["a","S","d","F","g","H","j","K","l","Z",]

function Invite({ location, match }) {

  const history = useHistory();

  useEffect(()=>{
    submit()
  }, [])

  const submit = async () => {
    try {
      const params = qs.parse(history.location.search)
      const { user } = params;

      let phoneNumber = "";

      for (let char of user.split("")){
        phoneNumber += mapper.indexOf(char);
      }

      const requestParams = {

        mode: 'no-cors',
        method: 'get',
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSeGaeoqItqztwlRurXpvHKlmPbc_3IOFEiw5I2MbFL44zFjrg/formResponse',
        params: {
          'entry.1412251046': phoneNumber
        }
      };
      await axios(requestParams)
    }
    catch (e) {
    }
    finally {
      alert("동의가 완료되었습니다")
      window.location = "/"
    }
  }


  
  return <></>
}

export default Invite;
