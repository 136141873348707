import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import parnters from '../../assets/image/partners@2x.png'

const Partners = () => {
  return (
    <Container>
      <Fade bottom distance="80px">
        <Title>
          파트너사와 함께 신뢰할 수 있는<br></br>
          서비스를 만들어나갑니다.
        </Title>
        <PartnersImage src={parnters}/>
      </Fade>
    </Container>

  )
}

const Container = styled.div`
  padding: 80px 0;
  background-color: #FBFBFB;
  text-align: center;
`;
const Title = styled.span`
  font-size: 40px;
  @media  (min-width: 320px) and (max-width: 767px) {
    font-size: 18px;
  }
`;
const PartnersImage = styled.img`
  margin-top: 40px;
  width: 50%;
  @media  (min-width: 320px) and (max-width: 767px) {
    width: 80%;
    margin-top: 16px;
  }
`;

export default Partners;