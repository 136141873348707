import React from "react";
import styled from "styled-components";

const TickleEvent = ({buttonText, buttonUrl, eventId}) => {
  const imageUrl = `https://tickle-public.s3.ap-northeast-2.amazonaws.com/event/${eventId}.png`
  const handleDetailButton = () => {
    if (window.ReactNativeWebView){
      const data = {
        type: "sendEvent",
        title: "event_cta",
        action: eventId
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data))
    }
    window.location = buttonUrl;
  };

  return (
    <>
      <Container>
        <FullImage src={imageUrl} />
      </Container>
      <Button onClick={handleDetailButton}>
        <ButtonText>{buttonText}</ButtonText>
      </Button>
      <input id="copyText" type="hidden" readOnly />
    </>
  );
};

const Container = styled.div`
  background-color: white;
  text-align: center;
  padding-bottom: 84px;
`;

const FullImage = styled.img`
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
  max-width: 768px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Button = styled.button`
  width: calc(100% - 40px);
  height: 50px;
  position: fixed;
  bottom: 34px;
  background-color: #ff9016;
  border-radius: 10px;
  border: 0;
  margin-left: 20px;
`;
const ButtonText = styled.span`
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
`;
export default TickleEvent;
