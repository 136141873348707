import React from 'react';
import './Questions.css';

import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const questions = [
   { question: '왜 제 계좌에서 티클 이름으로 돈이 출금되었나요?', answer: [
    '티클은 사용자님의 카드 이용내역을 계산하여 잔돈을 자동으로 저축해주는 서비스입니다.',
    '이 과정에서 매주 계산된 티클 내역만큼, 사용자님의 계좌에서 자동이체를 통해 연동된 저축 계좌로 저축해 드립니다.',
    '카드사 포인트 적립과 같은 방식이 아니니, 이 점 유의해주세요.'
  ]},
  
   { question: '이번주 월요일에 이체될 티클 금액은 어떻게 확인하나요?', answer: [
    '앱 내 [나의 자산]에서 주차별 티클 저축 내역을 확인하실 수 있습니다.'
  ]},
  
   { question: '탈퇴하면 저축된 돈이 자동으로 환불되나요?', answer: [
    '저축된 돈은 자동으로 환불되지 않으며, 사용자님께서 직접 출금해주셔야 합니다. 저축이 완료된 이후 사용자님의 앱 내 저축계좌에 있는 잔액을 출금하신 후, 환경설정 > 티클 탈퇴에서 탈퇴 진행 부탁드립니다.'
  ]},
   
   { question: '티클로 계산된 돈은 어디서 빠져나가나요?', answer: [
    '사용자님이 설정하신 출금계좌에서 매주 이체가 진행됩니다.',
    '출금계좌 확인 및 재설정은 [마이페이지] 탭 내 환경설정 메뉴에서 가능합니다.'
  ]}, 
   
  { question: '출금계좌에 돈이 없으면 티클은 어떻게 저축(이체)되나요?', answer: [
    '만일 출금계좌에 이체될 잔액이 부족할 경우, 이체가 발생하지 않으며, 다음 티클 저축 시 지난 주 티클에 해당하는 금액은 추가로 이체되지 않습니다.'
  ]},   
   
  { question: '왜 저축 예정 티클 내역이 0원으로 뜨나요?', answer: [
    '앱 내에서 표시되는 저축 예정 티클 내역은 월요일부터 새로 계산된 내역 기준으로 표시됩니다.',
    '이는 월요일부터 일요일까지의 연동된 카드의 사용내역 기준으로 계산되며, 매주 일요일에서 월요일로 넘어가는 자정에 리셋됩니다.'
  ]},
  
  { question: '카드연동하고 계좌개설까지 했는데, 다시 시작하라고 떠요. 이전 정보가 다 날아간 건가요?', answer: [
    '기존에 티클을 사용하시던 경우, 카드 연동 과정 재진행 이후 이전에 사용하시던 내역에 이어 티클 이용이 가능합니다.'
  ]},
  
  { question: '티클에서 카드사용내역은 확인되는데, 저축이 안 돼요.', answer: [
    '1. [환경설정 > 출금계좌 확인]을 통해 매주 티클이 빠져나가는 계좌를 확인하시고, 잔고 부족으로 인해 티클이 출금되지 않았는지 확인해주세요. (잔고가 모인 티클에 비해 1원이라도 부족할 경우, 매주 티클 출금이 진행되지 않습니다.)', 
    '2. 출금계좌 번호를 잘못 입력하셨을 수 있습니다. 출금계좌 등록을 다시 한 번 진행해주세요.'
  ]},
  
  { question: '티클 자동이체 신청 완료 문자는 왜 오나요?', answer: [
    '티클은 모인 잔돈을 일주일에 한 번, 자동이체를 이용하여 사용자님의 은행 계좌에서 티클 앱 내 저축계좌로 이체합니다. 자동이체 신청완료 문자는 사용자님의 은행 계좌가 정상적으로 티클 앱에 등록되었다는 문자입니다.'
  ]},
  
  { question: '카드 재연동을 요구하는 팝업이 자꾸 떠요.', answer: [
    '티클 앱 외부에서 카드사 비밀번호를 변경하셨을 경우 발생하는 문제입니다. 티클 앱 내에서 카드 재연동을 통해 다시 로그인을 해주세요.'
  ]},
  
  { question: '티클은 어떤 서비스인가요?', answer: [
    '티클은 잔돈의 개념을 활용해 소비, 저축, 그리고 재테크로 이어지는 자산관리를 위한 핀테크 서비스 앱입니다.',
    '잔돈 계산, 자동 저축, 투자의 3가지 기능으로 나누어져 있습니다.'
  ]},

  { question: '티클(잔돈)은 어떻게 쌓이나요?', answer: [
    '소비와 동시에 이루어지는 저축을 위해, 앱과 연동된 카드를 사용하실 때마다 결제 금액의 1,000원 단위로 잔돈을 계산합니다.\n(예: 4,200원 아메리카노를 마셨다면, 800원의 티클(잔돈)로 계산합니다.)',
    '이렇게 모인 티클의 일주일 치를 합산하여, 매주 월요일 고객님의 출금 계좌에서 저축 계좌로 자동이체(저축)를 해드립니다. (이체 수수료 0원)\n(예: 4,200원 아메리카노를 7일간 구매했다면 800 x 7의 잔돈을 계산하여 5,600원을 다음 주 월요일에 저축됩니다.)'
  ]},
  
  { question: '왜 1,000원 단위로 잔돈이 계산되나요?', answer: [
    '1,000원 단위로 잔돈(티클)이 생성되기 때문에 이해를 돕기 위해 1,000원 단위로 설정하였습니다.',
    '32,000원 같은 1,000원단위로 떨어지는 금액의 결제가 발생했을 시에는 저축을 장려하기 위해 1,000원을 티클로 저축해드리고 있습니다.'
  ]},
  
  { question: '회원가입 시 카드사 홈페이지 회원 가입은 왜 해야 하나요?', answer: [
    '티클은 앱과 연동된 고객님의 카드 승인내역을 바탕으로 잔돈을 계산하며, 이 과정에서 고객님의 카드사 회원정보를 활용합니다.',
    '만약 카드사 웹 회원가입을 아직 하지 않으신 경우, 티클을 통해 공인인증서 없이 간편하게 카드사 회원가입을 진행하실 수 있습니다.',
    '고객님의 정보는 모두 암호화되어 처리되며, 중요 정보는 저장되지 않으니 안심하셔도 됩니다. 자세한 내용은 앱 내 약관 페이지에서 "개인정보 수집 이용 동의"를 확인하세요.'
  ]},

  { question: '티클 탈퇴는 어떻게 하나요?', answer: [
    '티클 서비스 이용을 더 이상 원치 않으시는 경우, 티클 앱 내 마이페이지 > 환경설정 > 티클 탈퇴 선택 후 안내에 따라 진행해주시면 됩니다.',
    '앱 내에서 회원 탈퇴를 진행하지 않으신 채 티클 앱을 삭제하실 경우, 사용자님의 카드 사용 내역에 따른 티클 저축(이체) 이 계속 이루어지므로 꼭 앱 내에서 탈퇴를 진행해주세요.',
    '앱 사용 중 문제로 탈퇴 기능에 접근이 불가능하신 경우, 카카오톡 채널 @tickle을 통해 말씀해주시면 탈퇴 처리 도와드리겠습니다.'
  ]},
  
  { question: '이미 탈퇴 완료했는데, CMA계좌에 있던 돈을 찾고 싶어요!', answer: [
    '탈퇴 처리 이후, 사용자님의 CMA계좌에 저축된 금액에 대해서는 각 금융사를 통해 조회와 출금이 가능합니다.',
    'CMA 계좌 해지 역시 각 금융사 고객센터를 통해 진행하실 수 있습니다.'
  ]},
  
  { question: '내 카드가 앱과 연동이 잘 되어있는지 어떻게 확인하나요?', answer: [
    '앱 내 티클내역 탭에서 사용자님의 카드 사용내역을 확인하시면 됩니다.',
    '카드 사용 후 새로고침을 하여도 내역이 보이지 않는다면 카카오톡 채널 @tickle를 통해 문의주시기 바랍니다.'
  ]},
  
  { question: '아무 카드나 앱이랑 연동할 수 있나요?', answer: [
    '현재 티클은 국민, BC, 신한, 농협, 하나, 현대, 롯데, 삼성카드 및 카카오뱅크, 케이뱅크 체크카드 연동이 가능합니다.\n(우리, IBK기업, SC, 대구, 부산, 전북, 경남 카드는 BC카드를 통해 연결 가능합니다.)',
  ]},

  { question: '카드 여러 개를 동시에 연동할 수 있나요?', answer: [
    '환경설정 > 연동된 카드, 혹은 [티클 내역]탭의 카드 아이콘을 터치하여 카드 여러 개를 동시에 연동 가능합니다.'
  ]},

  { question: '티클 저축계좌는 무엇인가요?', answer: [
    '티클 저축계좌는 티클 회원가입과 동시에 생성되는 계좌입니다.',
    '티클 앱 내에서만 사용하실 수 있는 계좌로 별도의 이자는 지급되지 않습니다.',
    '티클 저축계좌는 티클 탈퇴 시 자동으로 해지되며, 계좌 잔액이 남아 있는 상태에서는 티클 탈퇴가 불가능합니다.'
  ]},
 
  { question: '티클 저축계좌와 저금통은 무엇이 다른가요?', answer: [
    '티클 저금통은 증권사가 발급하는 입출금통장인 CMA계좌의 다른 이름입니다.',
    '저금통 계좌에 저축된 돈은 정해진 수익률에 따라 하루 단위로 수익금이 지급되며, 수익률은 증권사에 따라 다릅니다.'
  ]},
  
  { question: '티클 탈퇴하면 개설한 티클 저금통 계좌도 해지되나요?', answer: [
    '개설하신 저금통 계좌는 티클 탈퇴 이후에도 각 증권사 홈페이지 또는 앱을 통해 정상적으로 이용가능합니다.',
    'CMA 계좌 해지를 원하시면 각 증권사 고객센터를 통해 진행해주세요.'
  ]},
 
  { question: '계좌개설 중 신분증 인식이 잘 안돼요!', answer: [
    '운전면허증보다 주민등록증의 인식률이 더 높습니다. 신분증의 앞면이 보이도록 놓아주세요. 어두운 바닥에 놓으면 더 잘 인식됩니다.',
    '신분증 표면에서 빛이 반사되지 않도록 주의하세요. 훼손이 심하거나 정보 확인이 어려운 신분증은 거절될 수 있습니다.'
  ]},   
   
  { question: '모인 티클이 이체가 안 돼요. (저축 대기 상태가 지속되고 있어요.)', answer: [
    '티클 앱에 계좌 연결이 정상적으로 안 되었을 수 있습니다. 환경설정 > 출금계좌 확인에서 등록된 출금계좌를 확인해 주세요. 문제가 지속될 경우 티클 앱에서 고객센터를 통해 문의 부탁드립니다.'
  ]},
  
  { question: 'CMA 계좌를 해지했는데도 계속 티클이 이체돼요.', answer: [
    '티클 일시중지 혹은 티클 탈퇴를 정상적으로 진행하지 않은 상태에서 계좌를 해지하셨을 때 발생하는 문제입니다.',
    'CMA 계좌를 고객님께서 직접 해지해주신 경우, 원칙적으로는 고객님의 계좌에서 출금 및 이체가 되지 않습니다. 혹여 출금이 진행된 경우, 출금된 돈은 원래 계좌로 다시 입금됩니다.'
  ]},  
   
  { question: '출금계좌에 돈이 없으면 티클은 어떻게 저축(이체)되나요?', answer: [
    '만일 출금계좌에 이체될 잔액이 부족할 경우, 이체가 발생하지 않으며, 다음 티클 저축 시 지난 주 티클에 해당하는 금액은 추가로 이체되지 않습니다.'
  ]},     
   
  { question: '저축 실패한 금액은 어떻게 되나요?', answer: [
    '한 번 저축에 실패한 금액은 다시 자동저축되지 않으며, 다음 저축 내역에 합산되어 저축되지도 않습니다.'
  ]},  
   
   { question: '티클 저금통의 수익금(이자) 은 어디에서 확인할 수 있나요?', answer: [
    '티클 저금통 계좌 (CMA계좌)를 개설해주신 증권사의 웹사이트 혹은 앱을 통해 확인 가능합니다. 티클 앱 내에서는 확인이 어려운 점 양해 부탁드립니다.',
    '티클로 저축된 금액 특성상 저축 금액이 그다지 크지 않아 하루 단위 수익의 조회가 다소 어려울 수 있습니다. 이는 CMA계좌에 저축한 금액에 따라 개인차가 있을 수 있으니 참고 부탁드립니다.'
  ]},    
   
  { question: '출금은 어떻게 하나요?', answer: [
    '1. 앱 내 "마이페이지" > "출금" 에서 저축하신 금액에 대한 출금이 가능합니다.',
    '2. 티클 저금통의 경우, 저금통을 개설해주신 증권사의 모바일 앱 혹은 웹사이트를 통해서도 출금 진행이 가능합니다.',
    '3. 미래에셋대우 CMA계좌를 이용하시는 고객님의 경우, 계좌에 모인 티클은 앱 내 \"마이페이지\" > \"출금\" 에서 네이버페이를 통해 출금 진행이 가능합니다.'
  ]},
  
  { question: '출금 수수료 및 출금 한도에 대해 알고 싶어요.', answer: [
    '티클 저금통에서는 월 4회까지 무료로 출금이 가능합니다. 5회차부터 건당 500원의 출금 수수료가 발생하며, 이는 출금 신청하신 금액과 함께 사용자님의 계좌에서 출금됩니다. 출금은 1일 최대 200만원까지 가능합니다.',
    '*네이버페이를 이용한 출금은 적용 대상이 아닙니다.',
    '*티클 저축계좌 및 데일리펀딩 예치금 계좌는 출금 시 별도의 수수료가 부과되지 않습니다.'
  ]},   
   
  { question: '티클 저축계좌에서 출금하는 방법을 모르겠어요.', answer: [
    '최초 출금 시 고객님 명의의 계좌를 이용한 1원 인증 과정 진행이 필요합니다.',
    '1. 티클 앱 내에서 환경설정 > 출금계좌 확인을 통해 티클 앱에 연동하신 계좌를 확인해주세요.',
    '2. 해당 계좌의 입출금내역을 모바일 뱅킹 앱을 통해 확인해주시고, 1원이 입금된 내역이 있는지 확인해주세요.',
    '3. 1원을 입금한 입금자명에 표시된 숫자 4자리를 문자 받으신 번호로 회신해주세요.',
    '4. 위의 과정을 완료하신 후, 문자로 전송된 [동의하실 경우 “**** (4자리 숫자)” 로 회신] 에 나와있는 4자리 숫자를 문자가 온 해당 번호로 회신해주시면 출금이 완료됩니다.'
  ]},
  
  { question: '출금 인증만 계속 진행되고, 다음 화면으로 안 넘어가요.', answer: [
    '미래에셋대우 CMA계좌에서 출금을 시도하셨을 시 간혹 발생하는 오류입니다.',
    '티클 앱이 아닌 네이버페이 웹사이트를 통해 미래에셋대우 계좌를 "간편계좌등록" 메뉴에서 등록해주신 뒤, "송금하기"메뉴를 통해서 출금을 진행해주세요.',
    '한 번만 진행해주시면 그 이후로는 티클 앱에서 정상적으로 출금이 가능합니다.'
  ]}, 
   
  { question: '티클 모으기는 어떻게 중지시키나요?', answer: [
    '티클 앱 내 마이페이지 > 환경설정 > 티클 옵션에서 티클 중지를 활성화해주시면 됩니다.'
  ]},

  { question: '티클 곱하기는 어떤 기능인가요?', answer: [
    '티클 곱하기는 고객님의 더욱 빠른 저축 목표 달성을 도와드리기 위한 기능입니다.',
    '만약 4,200원의 아메리카노를 구입 시 800원의 티클(잔돈)이 계산되었다면, 티클 곱하기 2배 (x2) 를 사용했을 경우 1,600원의 티클이 모이게 되는 방식입니다.',
    '현재 앱 내 환경설정 > 티클 옵션, 혹은 [티클 내역] 탭에서 이번주 티클 금액 옆에 표시된 [t]를 통하여 접근하실 수 있습니다.'
  ]},

  { question: '투자 가능한 시간은 언제인가요?', answer: [
    '티클에서 해외주식은 실시간으로 거래되는 것이 아니며, 영업일 (08:00-21:00) 및 휴일 (24시간)에 주문된 내역은 미국 주식 시장이 열리는 시점에 한 번에 주문됩니다. 미국 주식 시장이 쉬는 날은 평일이어도 주문이 안 될 수 있으니 참고해주세요.'
  ]},  
  
  { question: '환전을 해야 해외주식을 살 수 있나요?', answer: [
    '주식을 원화로 주문하면, 필요한 금액만큼 자동으로 환전됩니다.'
  ]}, 
   
  { question: '매도한 금액은 언제 출금 가능한가요?', answer: [
    '매도한 금액은 달러로 입금되며, 영업일 기준 3일 뒤 투자용 계좌에서 출금이 가능합니다. 출금을 원하실 경우 달러를 원화로 환전한 뒤 출금이 가능합니다.'
  ]},  
  
  { question: '구매 예약한 금액은 언제 출금되나요?', answer: [
    '구매가 완료된 다음 날 오전에 투자용 계좌에서 출금이 이루어집니다.'
  ]},  

  { question: '왜 구매 예약한 금액보다 적게 출금되나요?', answer: [
    '구매 시, 구매한 금액의 10% 만큼 가격 변동 예비금을 포함하여 주문이 이루어집니다. 가격변동 예비금은 주식의 가격이 전날 가격보다 많이 올랐을 경우에 대비한 금액으로, 주식 구매가 완료되어 환전이 이루어질 때 해외주식 계좌에 그대로 남아 있습니다.'
  ]},     
   
  { question: '왜 부족금액이 발생하나요?', answer: [
    '구매 시, 구매한 금액의 10% 만큼 가격 변동 예비금을 포함하여 주문이 이루어집니다. 가격변동 예비금은 주식의 가격이 전날 가격보다 많이 올랐을 경우에 대비한 금액으로 쓰이는데, 가격이 10%의 가격 변동 예비금을 초과할 만큼 급등하여 체결되었을 경우 부족금액이 발생할 수 있습니다.'
  ]},  
   
  { question: '주문이 취소되었어요.', answer: [
    '주식 구매가 확정되었을 때 계좌에 잔액이 부족하면 주문이 자동으로 취소됩니다.'
  ]},  
   
  { question: '주문(구매/매도)을 취소하고 싶어요.', answer: [
    '주문은 주문한 당일 오전 8시부터 오후 9시 사이에 취소하실 수 있습니다.'
  ]},
   
  { question: '매도한 주식은 원화로 입금되나요?', answer: [
    '매도한 주식은 달러로 입금됩니다. 입금된 달러는 향후 티클에서 해외주식을 구매할 때 우선적으로 사용되어 환전 비용을 아끼실 수 있습니다.'
  ]},

  { question: '진행하고 있던 챌린지가 바뀌었어요.', answer: [
    '챌린지를 개선하면서 챌린지 이름이 바뀌게 되었습니다. 목표 금액은 변하지 않았으니 안심하셔도 좋습니다. 두배로 챌린지는 \'두배로 내 맘대로\'로, 에어팟 챌린지는 \'티클모아 에어팟\'으로, 몰디브 챌린지는 \'티클모아 몰디브\'로, 그리고 자유 목표는 \'티클모아 내 맘대로\'로 변경되었습니다. 또한 이미 에어팟과 몰디브 챌린지를 하고 계신 분들은, 추천 티클 옵션도 이전 옵션으로 적용되고 있으니 안심하셔도 좋습니다. 티클모아 에어팟의 추천 티클 옵션은 곱하기 4배 & 정기 15,000원에서, 곱하기 2배 & 정기 티클 10,000원으로 변경되었고, 티클모아 몰디브의 추천 티클 옵션은 곱하기 4배 & 정기 티클 60,000원에서, 곱하기 3배 & 정기 티클 10,000원으로 변경되었습니다.'
  ]},

  { question: '투자 챌린지가 뭔가요?', answer: [
    '목표 수익률, 목표 수익금, 목표 총 자산을 설정하고 달성할 수 있도록 도와드리는 기능입니다. 챌린지 기간 동안 목표 수치를 달성하면 성공이고 그렇지 않으면 실패입니다. 챌린지에 성공해도 새로운 목표를 세워 다시 도전할 수 있으며, 실패했더라도 언제든 다시 도전할 수 있습니다.'
  ]},

  { question: '투자 챌린지를 여러 개 동시에 할 수 있나요?', answer: [
    '투자 챌린지는 여러 개를 동시에 도전할 수 있습니다.'
  ]},

  { question: '늘려보자! 나의 투자액 챌린지의 \'목표 투자액\'이 정확히 무엇을 의미하나요?', answer: [
    '투자액은 현재 투자 중인 금액을 의미합니다. 홈 탭에서 해외주식 계좌 대시보드에 가시면, 투자 중인 금액을 확인하실 수 있습니다. '
  ]},

  { question: '저축 챌린지가 뭔가요?', answer: [
    '설정한 목표 금액을 저축할 수 있도록 도와드리는 기능입니다. 목표 금액을 달성하면 성공이고 그렇지 않으면 실패입니다. 실패한 챌린지는 언제든 다시 도전할 수 있습니다. 저축 챌린지를 통해 저축 습관을 기르고 저축의 재미를 느껴보세요!'
  ]},

  { question: '저축 챌린지의 저축 내역은 언제부터 시작되나요?', answer: [
    '저축 챌린지를 시작한 이후 처음 다가오는 월요일부터 시작됩니다. 예를 들어 수요일에 저축 챌린지를 시작했다면, 다음주 월요일에 챌린지의 1주차 저축 내역을 확인하실 수 있습니다. 챌린지의 저축 내역은 저축이 진행되는 매주 월요일마다 업데이트됩니다.'
  ]},

  { question: '챌린지를 시작하고 \'3탭 티클 내역\'에서 \'바로 저축\'을 했는데, 챌린지 저축 내역에 안 떠요.', answer: [
    '가입 후 하신 바로 저축은 아쉽게도 챌린지 저축 내역으로 인정되지 않습니다. 챌린지 저축 내역은 매주 월요일마다 저축되는 내역만 인정하고 있습니다.'
  ]},
   
  { question: '저축 챌린지를 여러 개 동시에 할 수 있나요?', answer: [
    '저축 챌린지는 1개만 도전할 수 있습니다. 다른 저축 챌린지를 시작하려면, 현재 도전 중인 챌린지를 그만둬야 합니다.'
  ]},
   
  { question: '저축 챌린지를 하다가 티클 모이는 계좌를 변경했어요.', answer: [
    '티클 모이는 계좌를 변경해도 저축 챌린지를 진행 중이라면 목표 금액에 변동은 없습니다. 저축 챌린지를 시작한 시점부터 누적하여 저축액을 계산합니다.'
  ]},
   
  { question: '성공한 저축 챌린지는 다시 도전할 수 있나요?', answer: [
    '두배로 내맘대로, 티클모아 내맘대로 챌린지만 성공했을 때 다시 도전할 수 있습니다. 그 외의 저축 챌린지는 성공 시 다시 도전할 수 없습니다.'
  ]},

  { question: '저축과 투자 챌린지를 동시에 도전할 수 있나요?', answer: [
    '\'해외 주식 계좌\'가 있다면, 저축과 투자 챌린지를 동시에 도전할 수 있습니다. 티클 저금통 계좌만 있다면 저축 챌린지만 도전할 수 있으므로, 투자 챌린지를 하고 싶다면 \'해외 주식 계좌\'를 만들어야합니다.'
  ]},

  { question: '두배로 챌린지와 티클모아 챌린지의 차이점이 뭔가요?', answer: [
    '두배로 챌린지는 빠르게 목돈을 만들 수 있도록 도와주는 챌린지입니다. 티클 곱하기 2배를 적용하여, 매주 저축되는 금액의 두 배를 저축합니다. 반면에, 티클모아 챌린지는 사고 싶은 물건이나 여행 등 원하는 것을 위해 저축할 수 있도록 도와주는 챌린지입니다.'
  ]},
   
  { question: '현재 하고 있는 챌린지를 그만두고, 다른 챌린지를 하고 싶어요.', answer: [
    '현재 챌린지에서 그만두고 싶은 챌린지를 누릅니다. 그리고 화면 하단에 있는 \'챌린지 그만두기\'를 누르면 됩니다. 챌린지를 그만두면 실패한 챌린지가 되니, 신중히 생각하시고 결정해주세요.'
  ]},

  { question: '실패 또는 성공한 챌린지를 다시 시작하면 어떻게 되나요?', answer: [
    '실패 또는 성공한 챌린지를 다시 시작하면 초기화되어 챌린지를 시작하게 됩니다. 만약 현재 진행 중인 저축 챌린지가 있는데, 실패 또는 성공한 챌린지를 다시 시작하게 되면 현재 진행 중인 챌린지는 실패 상태로 전환됩니다.'
  ]}

]

export default class Questions extends React.PureComponent {

  render() {
    return (
      <Container className="faqContainer">
        <h1 className="menuTitle">고객센터</h1>
        {questions.map((v, i) => (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className='questionTitle'>Q. {v.question}</p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className="faqAnswerContainer"
            >
              {v.answer.map((text, i)=>(
                <div>
                  <p className="questionContent">
                  {/* <span>{i+1}. </span> */}
                  {text.split('\n').map((line,v)=>(
                  <span>{line}<br></br></span>
                  ))}
                  </p>
                  
                </div>
              ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Container>
    );

  }
}
