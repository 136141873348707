import React from 'react';
import mobile_top from '../../assets/image/uosgy_mobile_top.jpg';
import mobile_bottom from '../../assets/image/uosgy_mobile_bottom.jpg';
import pc_top from '../../assets/image/uosgy_pc_top.jpg';
import pc_bottom from '../../assets/image/uosgy_pc_bottom.jpg';
import buttonImage from '../../assets/image/uosgy_button.png';
import ReactGA from 'react-ga'

import './Uosgy.css';

const download =() => {
  window.location = '/download';
  ReactGA.event({category: 'appDownload',action:'click', label:'uosgy_promotion'});
}

const PC = () => {
  return(
    <div>
      <img src={pc_top} className="fullsizeImage" alt="pc_top" />
      <div style={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 45}}>
        <img src={buttonImage}  onClick={download} className="uosgyButton" alt="buttonImage" />
      </div>
      <img src={pc_bottom} className="fullsizeImage" alt="pc_bottom" />
        <div style={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 135}}>
          <img src={buttonImage} onClick={download} className="uosgyButton" alt="buttonImage" />
        </div>
    </div>
  )
}
const Mobile = () => {
  return(
    <div>
      <img src={mobile_top} className="fullsizeImage" alt="mobile_top" />
      <div style={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 45}}>
        <img src={buttonImage} onClick={download} className="uosgyButton" alt="buttonImage" />
      </div>
      <img src={mobile_bottom} className="fullsizeImage" alt="mobile_bottom" />
        <div style={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 135}}>
          <img src={buttonImage} onClick={download} className="uosgyButton" alt="buttonImage" />
        </div>
    </div>
  )
}

export default function TopArea() {

  return (
    <div >
      <div className="mobile">
        <Mobile />
      </div>
      <div className="pc">
        <PC />
      </div>
    </div>
  );
}
