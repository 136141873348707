import React from 'react';
import './DownloadFixed.css';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import {
  isIOS
} from "react-device-detect";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


// import { Carousel } from 'react-responsive-carousel';

const goDownload = ()=>{
  if(isIOS){
    window.location = 'https://tickleinvest.app.link/website';
    window.airbridge.events.send("web_waitlist_download");
    ReactPixel.track('web_waitlist_download');
    ReactGA.event({category: 'appDownload',action:'click', label:'apple'});
    ReactPixel.track('download_btn_apple');
  }
  else {
    window.location = 'https://tickleinvest.app.link/website';
    window.airbridge.events.send("web_waitlist_download");
    ReactPixel.track('web_waitlist_download');
    ReactGA.event({category: 'appDownload',action:'click', label:'play'})
    ReactPixel.track('download_btn_android');
  }
}

const goReserve = () => {
  window.location.href = '/promotion/earlybird_overseas_stock'
  window.airbridge.events.send("waitlist_enlist");
  ReactPixel.track("waitlist_enlist");
}

const StyledButton = withStyles({
  root: {
    background: '#ff9016',
    border: 0,
    color: '#ffffff',
    height: 50,
    width: '100%',
    padding: '0 30px',
    borderRadius: 0,
    '&:hover': {
        color: 'white',
        background: '#ff9016'
      }
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 15,
    fontFamily: ['Noto Sans KR', 'sans-serif']
  },
})(Button);

const StyledButtonReserve = withStyles({
  root: {
    background: '#fff7ee',
    border: 0,
    color: '#ff9016',
    height: 50,
    width: '50%',
    padding: '0 30px',
    borderRadius: 0,
    '&:hover': {
        color: '#ff9016',
        background: '#fff7ee'
      }
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 15,
    fontFamily: ['Noto Sans KR', 'sans-serif']
  },
})(Button);

export default class DownloadFixed extends React.PureComponent {

  

  render() {
    return (
      <div className="buttonBottomFixed">
        {/* <Container className="container"> */}
          <StyledButton variant="outlined" href="#" onClick={goDownload} >
            앱 다운로드
          </StyledButton>
          {/* <StyledButtonReserve variant="outlined" href="#" onClick={goReserve} >
            해외주식 사전예약
          </StyledButtonReserve> */}
        {/* </Container> */}
      </div>
    );

  }
}
