import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

export default function InviteFriends({ userGroup }) {

  return (
    <div>
      {window.location.href}
      <MetaTags>
        <title>3,000원을 받으세요! 티클 | 적금처럼 시작하는 해외주식</title>
        <meta name="description" content="친구와 함께 저축을 시작하고 3,000원을 받으세요!" />
        <meta property="og:title" content="3,000원을 받으세요! 티클 | 적금처럼 시작하는 해외주식" />
        <meta property="og:image" content="http://tickleinvest.com/invite-illust-piggy-light-orange.jpg" />
      </MetaTags>

    </div>
  );
}
