import React from 'react';
import Questions from '../components/Help/Questions';
import AppBar from '../components/AppBar'

function Help() {
  return (
    <div className="root">
      <AppBar orange />
      <Questions />
    </div>
  );
}

export default Help;
