import React from 'react';
import './Footer.css';


import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

export default class Footer extends React.PureComponent {

  render() {
    return (
      <div className="footer">
          <Container >
          <div>
            <Link to="/terms"  style={{marginRight: 15}}className="footerLink">서비스 이용약관  </Link>
            <Link to="/privacy" className="footerLink">개인정보 처리방침</Link>
          </div>
          <br></br>
          <p className="footerText">주식회사 티클 | 사업자등록번호: 771-88-01337 | 대표: 강상윤</p>
          <p className="footerText">(07223) 서울특별시 영등포구 당산동 6가 217-1 타워 020 7층</p>
          <p className="footerText">이메일 <a className="footerLink" href="mailto:support@tickleinvest.com">support@tickleinvest.com</a></p>
          <p className="footerText">카카오톡 <a href="https://pf.kakao.com/_xnApdT" className="footerLink">@tickle</a> | 대표번호 1544-5803</p>
          <br></br>
          <p className="footerText">© TICKLE Co., Ltd. All rights reserved. </p>
          </Container>
        </div>
    );

  }
}
