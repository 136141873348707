import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import privacy_file from '../assets/html/privacy_file'


function Home() {

  useEffect(() => {
    window.location = "https://tickle-public.s3.ap-northeast-2.amazonaws.com/process_privacy.pdf"
  }, [])

  return (
    <div className="root">
      {/* <Container>
      <div  style={{width: 645,marginTop: 120,marginBottom: 120,marginLeft: 'auto',marginRight: 'auto'}} dangerouslySetInnerHTML={{__html: privacy_file}}></div>
      </Container> */}
    </div>
  );
}

export default Home;
