import React from "react";
import queryString from "query-string";
import Uosgy from "../components/Promotion/Uosgy";
import InvestMoneySupport from "../components/Promotion/InvestMoneySupport";
import TheMoreCard from "../components/Promotion/TheMoreCard";
import PlanYes from "../components/Promotion/PlanYes";
import TickleEvent from "../components/Promotion/TickleEvent";

function Promotion({ location, match }) {
  const params = queryString.parse(location.search);

  const { target, imageUrl, buttonText, buttonUrl, eventId } = params;

  switch (match.params.promotion_title) {
    case "uosgy":
      return <Uosgy />;
    case "invest_money_support":
      return <InvestMoneySupport />;
    case "the_more_card":
      return <TheMoreCard target={target}/>;
    case "plan_yes":
      return <PlanYes/>;
    case "tickle_event":
      return <TickleEvent 
        imageUrl={imageUrl} 
        buttonText={buttonText} 
        buttonUrl={buttonUrl} 
        eventId={eventId}
      />;
    // Past Promotions
    // case "kbcard_airpod_event":
    //   return <KbcardAirpod userGroup={params.userGroup}/>
    // case "earlybird_overseas_stock":
    //   return <EarlyBirdOverseas/>
    // case "earlybird_overseas_stock_complete":
    //   return <EarlyBirdOverseasComplete />;
    // case "ticklean":
    //   return <Ticklean/>
    default:
      window.location = "/";
      return;
  }
}

export default Promotion;
