import React from "react";
import AppBar from "../AppBar";
import maindoor from "../../assets/image/illust/maindoor.png";

import "./TopArea.css";
import Container from "@material-ui/core/Container";

import StoreButton from "../Button/StoreButton";

// import Event from './Event'

export default function TopArea() {
  return (
    <div className="topBgContainer">
      <AppBar orange />
      <Container className="topAreaContainer">
        <div className="iconContainer">
          <div className="sloganContainer">
            <p className="slogan">적금처럼 시작하는</p>
            <p className="slogan">
              해외주식,
              <span className="slogan strike"> 티클</span>
            </p>
          </div>
          {/* <div className="icons">
            <StoreButton type="apple"/>
            <StoreButton type="play"/>
          </div> */}
        </div>
        <div className="handContainer">
          <img src={maindoor} alt="hand" className="hand" />
        </div>
      </Container>
      {/* <div className="arrow bounce"></div> */}
      {/* <Event /> */}
    </div>
  );
}
