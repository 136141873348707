import React from 'react';
import Fade from 'react-reveal/Fade';
import Container from '@material-ui/core/Container';

import coffeeSite from "../../assets/image/illust/tickleOptionSite.png";
import investMockup from "../../assets/image/investmentStatus.png";
import stockList from "../../assets/image/stockList@2x.png"

import './Spare.css';

export default function OverseasStock() {

  return (
    <div id="OverseasStock" className="introContainer white">
      <Container style={{ display: "flex"}} className="columnInMobileReverse">
          <div className="spareHalf">
            <Fade bottom distance="80px">
              <img src={investMockup} className="intro-mockup" alt="ticklehistory"/>
            </Fade>
          </div>
          <div className="spareHalf">
            <Fade bottom distance="80px" delay={200} className="introTexts">
              <img src={coffeeSite} className="titleIcon" />
              <p className="introTitle">소액으로 <b>부담없게</b></p>
              <span className="introContent">
                좋아하는 기업들을 0.01주 단위로<br></br>
                부담없이 소액투자 하세요!    
              </span>
              <img className="stockList" src={stockList} />
            </Fade>
          </div>
      </Container>
    </div>
  );
}
