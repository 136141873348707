import React from "react";
import Intro from "../components/Intro/TopArea";
import Banner from "../components/Intro/Banner";
import Pie from "../components/Intro/Pie";
import Divide from "../components/Intro/Divide";
import OverseasStock from "../components/Intro/OverseasStock";
import Steady from "../components/Intro/Steady";
import Reservation from "../components/Intro/Reservation";
import Partners from "../components/Intro/Partners";
import Features from "../components/Intro/Features";
import Reviews from "../components/Intro/Reviews";
import SubscribeLetter from "../components/Intro/SubscribeLetter";
import Footer from "../components/Footer";
import DownloadFixed from "../components/DownloadFixed";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../App.css";

function Home() {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="root">
      {/* <Modal
        open={modalIsOpen}
        onClose={closeModal}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <p>
          안녕하세요, 주식회사 티클입니다. <br />
          <br />
          티클 서비스 이용자 여러분께 우선 감사의 말씀드리며, 티클 서비스가
          2022년 2월 1일 종료하게 되었음을 안내 드립니다. <br />
          자세한 종료 일정은 아래 내용을 참고하시기 바랍니다. <br />
          <ul>
            <li>
              티클해외주식계좌(신한금융투자)/티클저금통계좌(삼성증권/미래에셋/KB)의
              경우 각 증권사에서 출금/매수/매도/파이 해지 등을 진행하실 수
              있습니다.
            </li>
            <li>
              티클 저축계좌(가상계좌) 보유 및 잔고가 있는 경우에는 다음의 링크를
              통하여 신청서를 작성해주시길 바랍니다. 접수 이후 출금이 20일 이상
              소요될 수 있습니다.
            </li>
          </ul>
          <a href="https://forms.gle/9NfsNKvtRTapGUAK9" target="blank">
            https://forms.gle/9NfsNKvtRTapGUAK9
          </a>
          <br />
          서비스 종료와 관련해서 불편한 사항이 없도록 최선의 노력을
          다하겠습니다. 티클을 아껴주신 모든 분께 다시 한 번 감사 인사를
          드립니다. <br />
          문의사항 접수, 출금 등 티클 서비스 종료와 관련된 모든 절차는 2023년 6월 30일을 끝으로 마감되며 이후 모든 문의사항과 출금 등에 대한 지원이 불가함을 안내드립니다.
          양해 부탁드립니다.
          <br />
          <br />
          티클 드림
        </p>
      </Modal> */}
      <Intro />
      {/* <Banner /> */}
      <Pie />
      <Divide />
      <OverseasStock />
      <Steady />
      {/* <Reservation /> */}
      <Features />
      <Reviews />
      <Partners />
      <SubscribeLetter />
      <Footer />
      <DownloadFixed />
      {/* <MockupSlider /> */}
    </div>
  );
}

export default Home;
