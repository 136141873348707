const reviews = [
    {
        title:"적금같은 티클파이 최고!",
        content:`적금 수익률 1%도 안나오는거 실화?!? 주식은 손실이 무서워서 못하고 있었는데 티클 파이는 적은 금액으로 주식을 적금처럼 쌓는 느낌에~ 자동으로 분산 투자해주니까 너무 좋아요`,
        source: 'App Store',
        author: '귯***'
    },
    {
        title:"경제 관련 컨텐츠가 휼륭해요",
        content:
`인스타 그램 보고선 앱 시작 했어요 ~ 경제 관련으로 컨텐츠도 훌륭 하고 ~ 주식 추천도 이해 되기 쉽게 해주셔서 감사했습니다 처음 들어 보는 ?? 그런 해외 기업들에 대해서도 많이 알게 되었어요 무엇도바 앱 사용도 편하고 귀엽게 잘 되어있네요 ^^`,
        source: 'App Store',
        author: '윤엥***'
    },
    {
        title:"재테크, 티클 덕에 시작했어요",
        content:
`재테크 뭔가요.. 은행에 돈만 넣어 놓다가 티클 알게 되고 잔돈도 모으고 해외주식도 사서 재태크 시작했어요ㅋㅋ`,
        source: 'App Store',
        author: 'ofi***',
    },
    {
        title:"투자가 재밌어요!",
        content:
`진짜 티끌이긴 하지만 점점 모이는 것도 투자하는 것도 재미있어용 그리구 돼지캐릭터 너무 귀여워요.`,
        source: 'App Store',
        author: 'dallifferen***'
    },
]

export default reviews