import React, { useRef, useState } from 'react';

import { Tab, Tabs, Container } from '@material-ui/core';
import Slider from "react-slick";

import Fade from 'react-reveal/Fade';

import mockup from '../../assets/image/main3-phone.png'
import cards from '../../assets/image/main3-cards.png'
import screenshot1 from '../../assets/image/pieList@2x.png'
import screenshot2 from '../../assets/image/tickleNow@2x.png'
import screenshot3 from '../../assets/image/ticklehistory@2x.png'
import screenshot4 from '../../assets/image/tickleHome@2x.png'

import icon1 from '../../assets/image/illust/oneTouchSite@2x.png';
import icon2 from '../../assets/image/illust/tickleLetterSite@2x.png';
import icon3 from '../../assets/image/illust/cardSite@2x.png';
import icon4 from '../../assets/image/illust/pieGraphSite@2x.png';


import './Features.css';

export default function Features() {

  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  const sliderSettings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  const infoTexts = [
    { title: '쉽고 간편한 첫 투자', menu: '티클파이', content: '미리 설정된 파이를 골라\n첫 투자도 쉽게 시작할 수 있어요', icon: icon1 },
    { title: '재미있는 첫 투자', menu: '티클나우', content: '떠먹여주는 콘텐츠들로\n첫 투자도 재미있게 시작하세요!', icon: icon2 },
    { title: '부담없는 잔돈 투자', menu: '잔돈투자', content: '카드를 쓸 때마다 모이는\n잔돈으로 부담없이 투자하세요!', icon: icon3 },
    { title: '한눈에 보는 자산 관리', menu: '자산관리', content: '한눈에 내가 투자한 주식과\n현금을 관리해보세요!', icon: icon4 },
  ]

  return (
    <div className="introContainer white featureIntroContainer">
      <Container style={{ display: "flex" }} className="columnInMobileReverse">
        <div className="spareHalf" style={{ zIndex: 10}}>
          <Fade bottom distance="60px" className="introTexts left featureTexts">
            <div className="featureInnerText">
              <div className="introTitleIconContainer">
                <img src={infoTexts[slideIndex].icon} className="introTitleIcon"/>
              </div>
              <p className="introTitle featureTitle">
                {infoTexts[slideIndex].title}
              </p>
              <div className="infoTabsContainer">
                <Tabs
                  value={slideIndex}
                  onChange={(event, value) => {
                    setSlideIndex(value)
                    sliderRef.current.slickGoTo(value)
                  }}
                  classes={{
                    indicator: 'infoTabsIndicator'
                  }}
                  variant='fullWidth'
                >
                  {infoTexts.map((item, index) => (
                    <Tab key={index} label={item.menu} onClick={() => { }} className="infoTab" />
                  ))}
                </Tabs>
              </div>
              <div className="featureContentContainer">
                <span className="introContent featureContent">{infoTexts[slideIndex].content}</span>
              </div>
            </div>
          </Fade>
        </div>
        <div className="spareHalf featureRight" >
          <Fade bottom distance="200px">
            <div className="mockupCardContainer">
              <Slider ref={sliderRef} {...sliderSettings} className="screenshotSlider" >
                <div className="screenshotContainer">
                  <img src={screenshot1} className="screenshot" alt="screenshot1" />
                </div>
                <div className="screenshotContainer">
                  <img src={screenshot2} className="screenshot" alt="screenshot2" />
                </div>
                <div className="screenshotContainer">
                  <img src={screenshot3} className="screenshot" alt="screenshot3" />
                </div>
                <div className="screenshotContainer">
                  <img src={screenshot4} className="screenshot" alt="screenshot4" />
                </div>
              </Slider>
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  );
}
