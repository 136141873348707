import React, { useState } from 'react';

import Fade from 'react-reveal/Fade';
import {
  withStyles,
} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import axios from 'axios'

import './SubscribeLetter.css';

const ColorButton = withStyles(() => ({
  root: {
    color: '#ff9016',
    width: 130,
    height: 56,
    marginLeft: 19,
    backgroundColor: '#FFF7EE',
    '&:hover': {
      backgroundColor: '#FFF7EE',
    },
    fontSize: 15,
    borderRadius: 25,
    fontWeight: 'bold',
    '@media  (min-width: 320px) and (max-width: 767px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))(Button);

const CssTextField = withStyles({
  root: {
    width: 500,
    marginLeft: 19,
    marginRight: 19,
    '& label.Mui-focused': {
      color: '#ff9016',
    },
    '& label': {
      // fontSize: 24,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#ff9016',
    },
    '& .MuiOutlinedInput-root': {
      // fontSize: 24,
      '& fieldset': {
        borderColor: 'rgb(255,144,22, 0.5)',
        borderRadius: 50,
        borderWidth: 2,
        '@media  (min-width: 320px) and (max-width: 767px)': {
          borderRadius: 10,
        }
      },
      '&:hover fieldset': {
        borderColor: '#ff9016',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff9016',
      },
    },
    '@media  (min-width: 320px) and (max-width: 767px)': {
      width: '100%',
      marginBottom: 25,
      marginLeft: 0,
      // borderRadius: 50,

      marginRight: 0,
    },
  },
})(TextField);

export default function SubscribeLetter() {

  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function sendForm() {
    if (isLoading) {
      return
    }
    if (!validateEmail(email)) {
      alert('올바른 이메일 주소를 입력해주세요.');
      return;
    }
    try {
      setIsLoading(true);
      await axios({
        mode: 'no-cors',
        method: 'post',
        url: 'https://stibee.com/api/v1.0/lists/SX3WYbgk6crCXkvHgg8sHLNSprHz/public/subscribers',
        params: {
          email
        }
      })
      setIsLoading(false);
      alert('티클 뉴스레터 구독 신청이 완료되었습니다!');
    }
    catch (e) {
      alert("입력한 정보를 확인 후 다시 시도해주세요.")
    }

  }
  return (
    <section id="subscribeLetter">
      <div className="introContainer white" style={{ flexDirection: 'column', alignItems: 'center', justifyItems: 'center', minHeight: 0 }}>
        <Container className="letterContainer">
          <Fade left distance="70px">
            <span className="letterWelcome">
              돈 되는 소식을 메일로!<br></br>
              <b>티클레터</b> 구독하기
            </span>
            <span className="letterWelcomeSub">
              바쁜 세상 속에서도 꼭 알아야 할 해외주식 뉴스를 전해드려요.<br></br>
              <b>매주 화요일, 목요일</b>마다 돈 되는 소식을 메일로 받아보세요.
            </span>
          </Fade>
          <Fade left distance="70px" delay={200}>
            <div className="letterButtons">
              <CssTextField
                id="email"
                type="email"
                label="이메일"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}

              />
              <ColorButton
                onClick={sendForm}
              >구독 신청</ColorButton>
            </div>
          </Fade>
        </Container>
      </div>
    </section>
  );
}
